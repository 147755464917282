export class Acquisition {
  instrument: string;
  ionisation?: string;
  ionization?: string;
  method: string;

  constructor(instrument: string, ionisation: string, method: string) {
    this.instrument = instrument;
    this.ionisation = ionisation;
    this.ionization = ionisation;
    this.method = method;
  }

  toString() {
    return `${this.method} | ${this.ionisation ? this.ionisation : this.ionization}`
  }
}
