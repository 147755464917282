<amplify-authenticator variation="modal" [formFields]="formFields">
  <ng-template amplifySlot="header" class="login-header">
    <div class="login-header">
      <h1 class="align-self-center align-content-center text-center">Binview Login</h1>
    </div>
  </ng-template>

  <ng-template amplifySlot="confirm-sign-up-footer">
    <div class="amplify-flex amplify-alert amplify-alert--warning justify-content-center">
      <p class="amplify-heading--5">Please contact us to have your account enabled.</p>
    </div>
  </ng-template>

  <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
    <app-navbar></app-navbar>
    <div class="m-4">
      <ng-container *ngIf="(stage$ | async) as s">
        <div class="stage-text"><b>{{ s }}</b></div>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</amplify-authenticator>
