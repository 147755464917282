<div class="dialog">
  <div class="dialog-title">
    <h3>File Import</h3>
  </div>
  <div class="content">
    <form [formGroup]="form">
      <div class="mx-2 d-flex">
        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Method name</mat-label>
          <input matInput type="text" formControlName="methodName" required>
          <mat-error *ngIf="methodName.errors">Invalid method name</mat-error>
        </mat-form-field>
      </div>

      <div class="mx-2 d-flex">
        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Instrument</mat-label>
          <input matInput type="text" name="instrument" formControlName="instrument" required>
          <mat-error *ngIf="instrument.errors">Invalid instrument name</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Column</mat-label>
          <input matInput type="text" name="column" formControlName="column" required>
          <mat-error *ngIf="column.errors">Invalid column name</mat-error>
        </mat-form-field>
      </div>

      <div class="mx-2 d-flex justify-content-evenly">
        <span class="m-2 flex-grow-1">
          <mat-form-field appearance="outline">
            <mat-label>Ionization Mode</mat-label>
            <mat-select placeholder="Select Ion Mode" formControlName="ionMode">
              <ng-container *ngFor="let im of ionModes">
                <mat-option [value]="im">{{ im }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </span>

        <span class="m-2 flex-grow-1">
          <mat-form-field appearance="outline">
            <mat-label>Target Type</mat-label>
            <mat-select placeholder="Select Target Type" formControlName="targetType">
              <ng-container *ngFor="let tt of targetTypes">
                <mat-option [value]="tt">{{ tt }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <div class="mx-2">
        <hr/>
        <div class="m-2 d-flex">
          <div class="d-flex">
            <mat-label class="me-2">Select File</mat-label>
            <button type="button" mat-raised-button color="primary"
                    [disabled]="form.invalid"
                    (click)="fileInput.click()">Choose File
            </button>
            <input type="file" hidden accept="application/vnd.ms-excel, text/csv"
                   (change)="onFileSelected($event.target.files)" #fileInput>
          </div>
          <div *ngFor="let err of fileErrors">
            <mat-error>{{ err }}</mat-error>
          </div>
        </div>

        <div class="mx-2">
          <mat-form-field class="d-flex" appearance="outline">
            <mat-label class="flex-grow-1">File contents ({{ targets.length }} targets)</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="fileContent"
                      class="flex-grow-1" style="white-space: pre; max-height: 200px"
                      cdkAutosizeMinRows="5" cdkAutosizeMaxRows="20" contenteditable="false"
                      [value]="stringFileContents$ | async">
            </textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="mx-2">
        <hr/>
        <div class="mx-2 d-flex flex-grow-1 justify-content-evenly">
          <button mat-raised-button color="primary"
                  [disabled]="form.invalid || fileErrors.length > 0"
                  [mat-dialog-close]="saveMethod()">Import
          </button>
          <button mat-raised-button mat-dialog-close color="warn">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>

