import { Environment } from './ienvironment';

export const environment: Environment = {
  production: false,
  development: true,
  name: 'test',
  BASEURL: 'https://test-api.metabolomics.us',
  CIS_URL: 'https://test-api.metabolomics.us/cis',
  STASIS_URL: 'https://test-api.metabolomics.us/stasis',
  UTILS_URL: 'https://test-api.metabolomics.us/utils',
  SCHEDULER_URL: 'https://test-api.metabolomics.us/scheduler',
  MINIX_URL: 'https://test-api.metabolomics.us/minix',
  SPECTROMETRY_URL: 'https://test-api.metabolomics.us/eic',
  FLASH_EIC_URL: 'https://flasheic.metabolomics.us/eic',
  CORRECTED_EIC_URL: 'https://flasheic.metabolomics.us/corrected',
  wsEndpoint: 'wss://test-ws.metabolomics.us',
  wsApiKey: '',
  wsJobUrl: 'wss://test.jobws.metabolomics.us/jobs',
  SAMPLE_NUMBER: 100,
  format: 'medium',  // ISO format (https://stackblitz.com/edit/angular-ivy-kdhxck?file=src%2Fapp%2Fapp.component.html)
  SPLASH_URL: 'https://splash.fiehnlab.ucdavis.edu',
  API_KEY: process.env['TEST_API_KEY'],
  SETTINGS_URL: 'https://test-api.metabolomics.us/settings',
  STATISTICS_URL: 'https://test-api.metabolomics.us',

  // go api's urls
  GOMON_URL: 'https://test-api.metabolomics.us/gomon',
  GOJOBS_URL: 'https://test-api.metabolomics.us/gojobs',
  GOSTASIS_URL: 'https://test-api.metabolomics.us/samples',
  GOADMIN_URL: 'https://test-api.metabolomics.us/goadmin',
  GOAUDIT_URL: 'https://test-api.metabolomics.us/audit'
};
