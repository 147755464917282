import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function createMsmsFormatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null;
    }

    const valid = /^\d+\.\d+:\d+(?:\.\d+)?(?:\s\d+\.\d+:\d+(?:\.\d+)?)*$/m.test(value);
    const resp = valid ? null : {msmsInvalidFormat: true};

    return resp;
  };
}
