import { Component, inject, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  dialog = inject(MatDialog);
  authService = inject(AuthService);
  router = inject(Router);

  stage: BehaviorSubject<string>;
  stage$: Observable<string>;
  title = 'binbase-scheduler';

  navigating$: Observable<boolean> = of(false);

  constructor() {
    Hub.listen('auth', (data) => {
      this.authService.onAuthEvent(data);
    });
  }

  public formFields = {
    signUp: {
      family_name: {
        placeholder: 'Enter your Last Name',
        label: 'Last Name:'
      },
      name: {
        label: 'First Name:',
        placeholder: 'Enter your First Name',
      }
    },
  };

  ngOnInit() {
    console.log('app init');
    this.stage = new BehaviorSubject<string>(environment.name !== 'prod' ? `*** VIEWING STAGE: ${environment.name.toUpperCase()} ***` : '');
    this.stage$ = this.stage.asObservable();
    this.navigating$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map(e => e instanceof NavigationStart)
    );
  }
}
