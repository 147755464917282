import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MethodsComponent } from './components/methods/methods.component';
import { MethodViewComponent } from './components/method-view/method-view.component';

const routes: Routes = [
  {path: 'methods', component: MethodsComponent},
  {path: 'method', component: MethodViewComponent},
  {path: '', redirectTo: 'methods', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StandardsMgmtRoutingModule {
  constructor() {
  }
}
