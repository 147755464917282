<h3>
  <i class="fas fa-star"></i>
  LC-BinBase Control Panel
</h3>

<hr>

<div class="row my-5">
  <div class="col-md-3 mb-3">          <!-- ATG -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa-solid fa-list-check"></i>
        <div class="panel-title">
          ACQUISTION TABLE GENERATION
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Create sample acquisition lists to be used in the instruments, or download previously created
        acquisition lists and job files</h6>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-rebel"></i>Create LC-MS Acquisition Table</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/acquisition/lcms">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-empire"></i>Create GC-MS Acquisition Table</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/acquisition/gcms" [disabled]="true">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-rebel"></i>Download acquisition files</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/acquisition/downloads">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3 mb-3">          <!-- SAMPLES -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa-solid fa-droplet"></i>
        <div class="panel-title">
          EXPERIMENTS
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Check the status of samples in your studies</h6>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-solid fa-file-waveform"></i>MiniX Search and Sample Updates</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/experiment/main">
          Report <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <!-- Moved here for visual balance, can move it down to scheduler if necessary -->
      <div class="mb-4">
        <h6><i class="me-2 fas fa-search-plus"></i>Sample Search</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/experiment/sample">
          Search
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3 mb-3">          <!-- JOB SCHEDULER -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa fa-calendar"></i>
        <div class="panel-title">
          JOBS
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Schedule samples from an acquisition table for processing</h6>
      <div class="mb-4">
        <h6><i class="me-2 fas fa-search-plus"></i>Job Search</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/scheduler/dataViewer">
          Search
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 far fa-calendar-check"></i>Create A Job</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/scheduler/container">
          Create
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fa-solid fa-object-group"></i>Group Jobs</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/scheduler/group-job">
          Go
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fas fa-search-plus"></i>Job Metadata</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/scheduler/metadata">
          Search
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3 mb-3">          <!-- BINVIEW SERVICES -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa-solid fa-chart-column"></i>
        <div class="panel-title">
          BINVIEW SERVICES
        </div>
      </div>
      <hr>
      <h6 class="mb-4">View and compare compounds from LC-BinBase with other libraries</h6>
      <div class="mr-4 mb-4">
        <h6><i class="me-2 fas fa-fingerprint"></i>BinView</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/binview/binview">
          Browse
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mr-4 mb-4">
        <h6><i class="me-2 fas fa-search-plus"></i>Compound Search</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/binview/compound">
          Search
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mr-4 mb-4">
        <h6><i class="me-2 fa-solid fa-clone"></i>Duplicates and Fragment Ions</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/binview/duplicates">
          Browse
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mr-4 mb-4">
        <h6><i class="me-2 fa-solid fa-passport"></i>Upload Identifiers</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/binview/id">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row my-5">
  <div *ngIf="false" class="col-md-3 mb-3">          <!-- Standards Management -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa fa-signal"></i>
        <div class="panel-title">
          Standards Management
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Allows creation and editing of Internal Standards</h6>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-table"></i>Available Methods</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/standards">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3 mb-3">          <!-- EVENTS -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa fa-clock-o"></i>
        <div class="panel-title">
          EVENTS
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Monitors what is happening with samples and jobs</h6>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-solid fa-file-waveform"></i>Sample Events</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/experiment/main">
          Watch <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fa fa-solid fa-file-waveform"></i>All Events Monitor</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/experiment/events">
          Watch <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="me-2 fa-solid fa-file-waveform"></i>Job Events</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/scheduler/job-events">
          Watch <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="fa-solid fa-clock-rotate-left me-2"></i>Bin and Sample Change Logs</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/audit">
          Search <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-3 mb-3">          <!-- UTILITIES -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa-solid fa-screwdriver-wrench"></i>
        <div class="panel-title">
          UTILITIES
        </div>
      </div>
      <hr>
      <h6 class="mb-4">Basic tools separate from LC-BinBase APIs</h6>
      <div class="mb-4">
        <h6><i class="fa-solid fa-chart-line me-2"></i>View EIC</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/utility/eic">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="fa-solid fa-chart-simple me-2"></i>View Spectrum</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/utility/spectrum">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
      <div class="mb-4">
        <h6><i class="fa-solid fa-wand-magic-sparkles me-2"></i>Edit Formatting</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/utility/transpose">
          Start
          <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-3 mb-3"> <!-- MONITOR -->
    <div class="column-panel p-3">
      <div class="panel-header">
        <i class="fa-solid fa-eye"></i>
        <div class="panel-title">MONITOR</div>
      </div>
      <hr>
      <h6 class="mb-4">Monitor QC and Instrument Annotations</h6>
      <div class="mb-4">
        <h6><i class="fa-solid fa-microscope me-2"></i>QC Monitor</h6>
        <button type="button" mat-raised-button color="primary" routerLink="/monitor">
          Start <i class="fas fa-arrow-circle-right"></i>
        </button>
      </div>
    </div>
  </div>


</div>
