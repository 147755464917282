<div class="dialog">
  <div class="dialog-title">
    <h3>{{title}}</h3>
  </div>
  <div class="content">
    <form [formGroup]="istdForm">
      <h4 class="m-2">Method</h4>
      <div class="mx-2 d-flex">
        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Method name</mat-label>
          <input matInput type="text" formControlName="method">
          <mat-error *ngIf="method.errors">Invalid method name</mat-error>
        </mat-form-field>
      </div>

      <div class="mx-2 d-flex">
        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Instrument</mat-label>
          <input matInput type="text" formControlName="instrument">
          <mat-error *ngIf="instrument.errors">Invalid instrument name</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="m-2 flex-grow-1">
          <mat-label>Column</mat-label>
          <input matInput type="text" formControlName="column">
          <mat-error *ngIf="column.errors">Invalid column name</mat-error>
        </mat-form-field>
      </div>

      <div class="mx-2 d-flex justify-content-evenly">
        <span class="m-2 flex-grow-1">
          <mat-form-field appearance="outline">
            <mat-label>Ionization Mode</mat-label>
            <mat-select placeholder="Select Ion Mode" formControlName="ion_mode">
              <ng-container *ngFor="let im of ionModes">
                <mat-option [value]="im">{{ im }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </span>

        <span class="m-2 flex-grow-1">
          <mat-form-field appearance="outline">
            <mat-label>Target Type</mat-label>
            <mat-select placeholder="Select Target Type" formControlName="target_type">
              <ng-container *ngFor="let tt of targetTypes">
                <mat-option [value]="tt">{{ tt }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </span>
      </div>

      <hr class="mx-2"/>

      <div class="my-2 d-flex">
        <h4 class="m-2 flex-grow-1">Target</h4>

        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Id</mat-label>
          <input matInput type="text" name="id" formControlName="id">
        </mat-form-field>

        <mat-slide-toggle class="mx-2" formControlName="hidden" labelPosition="before">
          <span class="hidden">Hidden</span>
        </mat-slide-toggle>
      </div>

      <div class="my-2 d-flex">
        <mat-form-field class="mx-2 flex-grow-1" appearance="outline">
          <mat-label>Compound name</mat-label>
          <input matInput type="text" formControlName="identifier">
          <mat-error *ngIf="identifier.errors">Missing compound name</mat-error>
        </mat-form-field>
      </div>

      <div class="my-2 d-flex justify-content-evenly">
        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Adduct</mat-label>
          <input matInput type="text" formControlName="adduct">
          <mat-error *ngIf="adduct.errors">Missing compound name</mat-error>
        </mat-form-field>

        <mat-form-field class="mx-2 flex-grow-1" appearance="outline">
          <mat-label>InChIKey</mat-label>
          <input matInput type="text" formControlName="inchi_key">
          <mat-error *ngIf="inchi_key.errors">Invalid InChIKey</mat-error>
        </mat-form-field>
      </div>

      <div class="my-2 d-flex justify-content-evenly">
        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Accurate Mass</mat-label>
          <input matInput type="number" placeholder="Accurate Mass" formControlName="accurate_mass">
          <mat-error *ngIf="accurate_mass.errors">Invalid Accurate Mass</mat-error>
        </mat-form-field>

        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Precursor Mass</mat-label>
          <input matInput type="number" placeholder="Precursor Mass" formControlName="pre_cursors_mass">
          <mat-error *ngIf="pre_cursors_mass.errors">Invalid Precursor Mass</mat-error>
        </mat-form-field>

        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Precursor Intensity</mat-label>
          <input matInput type="number" placeholder="Precursor Intensity" formControlName="pre_cursors_intensity">
          <mat-error *ngIf="pre_cursors_mass.errors">Invalid Precursor Intensity</mat-error>
        </mat-form-field>
      </div>

      <div class="my-2 d-flex justify-content-evenly">
        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Rerention Index</mat-label>
          <input matInput type="number" placeholder="Retention Index" formControlName="retention_index">
          <mat-error *ngIf="retention_index.errors">Invalid Retention Index</mat-error>
        </mat-form-field>

        <mat-form-field class="mx-2" appearance="outline">
          <mat-label>Retention Index Unit</mat-label>
          <mat-select placeholder="RI Unit" formControlName="ri_unit">
            <mat-option value="minutes">Minutes</mat-option>
            <mat-option value="seconds">Seconds</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="my-2 d-flex justify-content-evenly">
        <mat-form-field class="mx-2 flex-grow-1" appearance="outline">
          <mat-label>Splash</mat-label>
          <input matInput type="text" readonly formControlName="splash">
        </mat-form-field>
      </div>

      <div class="my-2 d-flex justify-content-evenly">
        <mat-form-field class="mx-2 flex-grow-1" appearance="outline">
          <mat-label>MSMS Spectrum</mat-label>
          <textarea matInput cdkTextareaAutosize class="flex-grow-1"
                    cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                    name="msms" formControlName="msms">
          </textarea>
          <mat-error *ngIf="msms.errors?.required">Missing MSMS Spectrum</mat-error>
          <mat-error *ngIf="msms.errors?.msmsInvalidFormat">Invalid format. Please verify that all the masses are in
            decimal format
          </mat-error>
        </mat-form-field>
      </div>
      <div class="my-2">{{ msms.errors }}</div>

      <div class="my-2 d-flex justify-content-evenly">
        <button mat-raised-button color="primary"
                [disabled]="!istdForm.valid"
                (click)="saveIstd(istdForm.value)">{{ addButtonLabel }}
        </button>
        <button mat-raised-button color="warn"
                (click)="close()">Cancel
        </button>
      </div>
    </form>
  </div>
  <br/>

</div>
