import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IonMode } from '../../models/IonMode';
import { IstdService } from '../../services/istd.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Istd } from '../../models/Istd';
import { TargetType } from '../../models/TargetType';
import { createMsmsFormatValidator } from '../../validators/msms.validator';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { toLower } from 'lodash-es';
import { MethodPipe } from '../../utils/method.pipe';

@Component({
  selector: 'app-istd-edit',
  templateUrl: './istd-edit.component.html',
  styleUrls: ['./istd-edit.component.css']
})
export class IstdEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { istd: Istd, mode: string, title?: string, file?: string, source: string },
    private store: IstdService,
    private fb: UntypedFormBuilder,   // TODO: find a typed alternative
    public dialogRef: MatDialogRef<IstdEditComponent>,
    private mp: MethodPipe,
  ) {
    if (data?.mode === 'edit') {
      this.title = 'Edit Target';
      this.editMode = true;
    }

    this.source = data?.source;

    dialogRef.backdropClick().subscribe(dialogRef.close);
  }

  title = this.data?.title ? this.data?.title : 'Add Target';
  editMode = false;
  addButtonLabel = 'Add';
  source: string;

  ionModes = Object.values(IonMode).map(toLower);
  targetTypes = Object.values(TargetType);

  istdForm: UntypedFormGroup;

  ngOnInit(): void {
    this.istdForm = this.fb.group({
      id: [{value: null, disabled: true}],
      method: [{value: null, disabled: this.editMode}, [Validators.required]],
      instrument: [{value: null, disabled: this.editMode}, [Validators.required]],
      column: [{value: null, disabled: this.editMode}, [Validators.required]],
      ion_mode: [{value: null, disabled: this.editMode}, [Validators.required]],
      identifier: [null, [Validators.required]],
      accurate_mass: [0.0, [Validators.required, Validators.min(1.0)]],
      pre_cursors_mass: [0.0, [Validators.required, Validators.min(1.0)]],
      pre_cursors_intensity: [0, [Validators.required, Validators.min(0)]],
      retention_index: [0.0, [Validators.required, Validators.min(0.0)]],
      ri_unit: ['seconds', [Validators.required]],
      target_type: [TargetType.istd],
      inchi_key: ['', [Validators.pattern('[A-Z]{14}-[A-Z]{10}-[A-Z]')]],
      adduct: [null, [Validators.required]],
      version: ['', []],
      hidden: [false, {readonly: true}],
      splash: [null, {readonly: true}],
      msms: [null, {
        validators: [createMsmsFormatValidator()], // Validators.required,
        asyncValidators: [],
        updateOn: 'change'
      }],
      sample: `imported from external library file ${this.data?.file}`,
    });

    // const ak = Object.entries(this.data?.istd)?.sort((a, b) => a < b ? -1 : 1);
    // const bk = Object.entries(this.istdForm.controls).sort((a, b) => a < b ? -1 : 1);
    // console.log(ak, bk);
    if (this.data.istd) {
      // this.data.istd.ion_mode = this.data.istd.ion_mode as IonMode;
      this.istdForm.patchValue(this.data.istd, {emitEvent: false});
    }

    if (this.data?.mode === 'edit') {
      this.editMode = true;
      this.addButtonLabel = 'Update';
    }

    this.istdForm.get('msms').valueChanges
    .pipe(
      debounceTime(300),
      filter(() => this.istdForm.get('msms').valid),
      switchMap(msmsstr => this.store.getSplash$(msmsstr?.trim())),
      tap(splash => this.istdForm.get('splash').setValue(splash)),
    ).subscribe();
  }

  get method(): AbstractControl {
    return this.istdForm.controls.method;
  }

  get instrument(): AbstractControl {
    return this.istdForm.controls.instrument;
  }

  get column(): AbstractControl {
    return this.istdForm.controls.column;
  }

  get identifier(): AbstractControl {
    return this.istdForm.controls.identifier;
  }

  get adduct(): AbstractControl {
    return this.istdForm.controls.adduct;
  }

  get accurate_mass(): AbstractControl {
    return this.istdForm.controls.accurate_mass;
  }

  get pre_cursors_mass(): AbstractControl {
    return this.istdForm.controls.pre_cursors_mass;
  }

  get retention_index(): AbstractControl {
    return this.istdForm.controls.retention_index;
  }

  get msms(): AbstractControl {
    return this.istdForm.controls.msms;
  }

  get inchi_key(): AbstractControl {
    return this.istdForm.controls.inchi_key;
  }

  saveIstd(istd: Istd): void {
    const user = localStorage.getItem('user');

    const method = this.istdForm.controls.method.value + ' | ' +
    this.istdForm.controls.instrument.value + ' | ' +
    this.istdForm.controls.column.value + ' | ' +
    this.istdForm.controls.ion_mode.value as IonMode;

    const name = istd.identifier;

    const nIstd = {
      ...istd, ...{
        id: this.istdForm.controls.id.value,
        name: name,
        method,
        ion_mode: this.istdForm.controls.ion_mode.value
      }
    };
    delete nIstd.identifier;

    if (this.source === 'database') {
      this.store.addIstd(nIstd)
      .pipe(
        tap(s => console.log('response:', s)),
        // catchError(err => {
        //   console.log(err);
        //   return of(null);
        // }),
      ).subscribe();
    }
    this.dialogRef.close(nIstd);
  }

  close(): void {
    console.log('cancel');
    this.dialogRef.close();
  }

}
