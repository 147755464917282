<mat-tab-group class="m-4 border-danger">
  <mat-tab *ngFor="let tt of (targetTypes$ | async)"
           [hidden]="(filterIstds(tt) | async).length < 1"
           [label]="create_label(tt, (filterIstds(tt) | async).length)">
    <div class="d-flex align-top" *ngIf="(filterIstds(tt) | async).length > 0; else noTargets">
      <div class="d-flex target-list align-top">
        <div *ngFor="let istd of (filterIstds(tt) | async)">
          <a id="{{md5(istd.identifier)}}"></a>
          <app-istd-view [istd]="istd"></app-istd-view>
        </div>
      </div>

      <div class="ms-2 side-list align-top">
        <h4>Index</h4>
        <p *ngFor="let istd of (filterIstds(tt) | async)">
          <a [routerLink]="[]" fragment="{{md5(istd.identifier)}}">{{istd.identifier}}</a>
        </p>
      </div>
    </div>
    <ng-template #noTargets>
      <p class="card-justified no-targets">No targets of type: {{tt}} </p>
    </ng-template>
  </mat-tab>
</mat-tab-group>
