import {Acquisition} from './sample.acquisition.model';
import {Processing} from './sample.processing.model';
import {Metadata} from './sample.metadata.model';
import {Reference} from './sample.reference.model';
import {Userdata} from './sample.userdata.model';
import {Chromatography} from "./sample.chromatography.model";

export class SampleData {
  sample: string;
  experiment: string;
  acquisition: Acquisition;
  chromatography: Chromatography;
  processing: Processing;
  metadata: Metadata;
  userdata: Userdata;
  references: Array<Reference>;
  time?: number;
  id?: string;
  versions?: any;

  constructor(sample: string, experiment: string, acquisition: Acquisition, chromatography: Chromatography,
              processing: Processing, metadata: Metadata, userdata: Userdata, references: Array<Reference>) {

    this.sample = sample;
    this.experiment = experiment;
    this.acquisition = acquisition;
    this.chromatography = chromatography;
    this.processing = processing;
    this.metadata = metadata;
    this.userdata = userdata;
    this.references = references;
  }
}
