import { Component, OnInit } from '@angular/core';
import { IonMode } from '../../models/IonMode';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Istd } from '../../models/Istd';
import { TargetType } from '../../models/TargetType';
import { IstdService } from '../../services/istd.service';

@Component({
  selector: 'app-method-edit',
  templateUrl: './method-edit.component.html',
  styleUrls: ['./method-edit.component.css']
})
export class MethodEditComponent implements OnInit {

  constructor(
    private store: IstdService,
    private fb: UntypedFormBuilder,
    // public dialogRef: MatDialogRef<MethodEditComponent>
  ) {
    // dialogRef.backdropClick().subscribe(() => {
    //   this.close();
    // });
    // dialogRef.updateSize('885px');
  }

  ionModes = Object.values(IonMode);
  targetTypes = Object.values(TargetType);

  methodForm = this.fb.group({
    method_name: [null, [Validators.required]],
    instrument: [null, [Validators.required]],
    column: [null, [Validators.required]],
    ionMode: [IonMode.positive, [Validators.required]],
    name: [null, [Validators.required]],
    accurate_mass: [0.0, [Validators.required, Validators.min(1.0)]],
    pre_cursors_mass: [0.0, [Validators.required, Validators.min(1.0)]],
    pre_cursors_intensity: [0],
    retention_index: [0.0, [Validators.required, Validators.min(0.0)]],
    ri_unit: ['seconds', [Validators.required]],
    target_type: [TargetType.istd],
    inchi_key: [''],
    adduct: [''],
    version: ['fixed'],
    hidden: [{value: false, disabled: true}],
    splash: [null, {readonly: true}],
    msms: [null, {
      validators: [Validators.required],
      asyncValidators: [],
      updateOn: 'blur'
    }],
    sample: ['imported from external libary file'],
  });

  ngOnInit(): void {
  }

  get method_name(): AbstractControl {
    return this.methodForm.controls.method_name;
  }

  get instrument(): AbstractControl {
    return this.methodForm.controls.instrument;
  }

  get column(): AbstractControl {
    return this.methodForm.controls.column;
  }

  get name(): AbstractControl {
    return this.methodForm.controls.name;
  }

  get accurate_mass(): AbstractControl {
    return this.methodForm.controls.accurate_mass;
  }

  get pre_cursors_mass(): AbstractControl {
    return this.methodForm.controls.name;
  }

  get retention_index(): AbstractControl {
    return this.methodForm.controls.retention_index;
  }

  get msms(): AbstractControl {
    return this.methodForm.controls.msms;
  }

  saveMethod(): void {
    const f = this.methodForm.value;
    const method = `${f.method_name} | ${f.instrument} | ${f.column} | ${f.ionMode}`;
    const istd = {
      method, splash: f.splash, version: f.version, accurate_mass: f.accurate_mass,
      identifier: f.name, adduct: f.adduct, inchi_key: f.inchikey, hidden: f.hidden,
      msms: f.msms, pre_cursors_mass: f.pre_cursors_mass, retention_index: f.retention_index,
      target_type: f.target_type, sample: f.sample
    } as Istd;

    this.store.addIstd(istd).subscribe();
    // this.dialogRef.close(istd);
  }

  close(): void {
    console.log('cancel');
    // this.dialogRef.close();
  }
}
