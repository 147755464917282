import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Istd } from '../../models/Istd';
import { Method } from '../../models/Method';
import { MethodPipe } from '../../utils/method.pipe';
import { IstdService } from '../../services/istd.service';
import { Observable, of } from 'rxjs';
import { TargetType } from '../../models/TargetType';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-istds',
  templateUrl: './istds.component.html',
  styleUrls: ['./istds.component.css']
})
export class IstdsComponent implements OnInit {

  @Input()
  method: Method;

  @Output()
  sendIstds: EventEmitter<Istd[]> = new EventEmitter();

  istds$: Observable<Istd[]>;

  targetTypes$: Observable<TargetType[]>;

  constructor(
    private route: ActivatedRoute,
    private mp: MethodPipe,
    private store: IstdService,
  ) {
  }

  ngOnInit(): void {
    this.istds$ = this.store.loadIstdsForMethod(this.method);
    this.targetTypes$ = of(Object.values(TargetType))
      .pipe(
        map(ts => {
            return ts.filter(t => !['confirmed', 'unconfirmed', 'invalid_target'].includes(t.toLowerCase()))
              .map(t => t as TargetType);
          }
        ),
      );
  }

  public filterIstds(targetType: string): Observable<Istd[]> {
    return this.istds$
      .pipe(
        map(raw =>
          raw.filter(i => i.target_type as TargetType === targetType as TargetType)
        ),
        tap(istds => this.sendIstds.emit(istds)),
        shareReplay(),
      );
  }

  create_label(tt, count) {
    return `${tt} (${count})`;
  }

  md5(label: string): string {
    return Md5.hashStr(label);
  }

}
