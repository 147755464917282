<div>
  <h3><i class="me-2 fa fa-signal"></i>Internal Standards Management</h3>
  <h4 class="text-muted">Manage the internal standards for the different Lab's methods</h4>
</div>
<br>

<ng-template #tplMethodList let-methods="methods" let-source="source">
  <div class="mx-4 d-flex">
    <h3 class="flex-grow-1">Methods from {{source}}</h3>
    <span class="flex-grow-1 justify-content-evenly" *ngIf="!isProd()">
      <span class="mx-2">method count: {{methods.length}}</span>
      <span class="mx-2 small">(non-production)</span>
    </span>
    <span>
      <button class="me-2" mat-raised-button color="primary">
        <mat-icon>add_circle</mat-icon>
        <span (click)="newMethod(source)">New Method</span>
      </button>
      <button mat-raised-button color="primary" *ngIf="source !== 'database'">
        <mat-icon>file_open</mat-icon>
        <span (click)="selectFile()">Import file</span>
      </button>
    </span>
  </div>
  <div class="mx-4">
    <mat-list class="method-list">
      <mat-list-item class="method-item" *ngFor="let m of methods">
        <span matListItemLine class="d-flex">
          <span class="method-name">{{ m | method }}</span>
          <span class="method-meta me-0" matListItemMeta>
            <mat-chip-set>
              <mat-chip><span class="chip-item">Total ({{m.targets?.length}})</span></mat-chip>
              <mat-chip *ngFor="let mt of m.types">
                <span class="chip-item">{{ mt.type }} ({{ mt.count }})</span>
              </mat-chip>
            </mat-chip-set>
            <span class="buttons">
              <button mat-raised-button color="primary" (click)="showMethod(m, source)">
                <mat-icon>visibility</mat-icon>
                <span>View</span>
              </button>
            </span>
          </span>
        </span>
      </mat-list-item>
    </mat-list>
  </div>
</ng-template>

<div class="width: 100%">
  <ng-template
    *ngTemplateOutlet="tplMethodList; context: { methods: filemethods$ | async, source: 'files'}"></ng-template>
  <hr/>
  <ng-template
    *ngTemplateOutlet="tplMethodList; context: { methods: dbmethods$ | async, source: 'database'}"></ng-template>
</div>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
</ng-container>
