import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Method } from '../../models/Method';
import { MatDialog } from '@angular/material/dialog';
import { IstdEditComponent } from '../istd-edit/istd-edit.component';
import { Istd } from '../../models/Istd';
import { YmlIstd, YmlMethod } from '../../models/yml-models';
import { stringify } from 'yaml';
import { saveAs } from 'file-saver';
import * as rp from '../../shared/regexPatterns';
import { IonMode } from '../../models/IonMode';
import { LoadingService } from '../../services/loading.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { TargetType } from '../../models/TargetType';
import { IstdService } from '../../services/istd.service';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-method-view',
  templateUrl: './method-view.component.html',
  styleUrls: ['./method-view.component.css']
})
export class MethodViewComponent implements OnInit {

  @Input()
  method: Method;

  @Input()
  source: 'files' | 'database';

  istds: Istd[];

  loading$: Observable<boolean>;

  @Output()
  sendIstds: EventEmitter<Istd[]> = new EventEmitter();

  istds$: Observable<Istd[]>;

  targetTypes$: Observable<TargetType[]>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private addIstdDlg: MatDialog,
    private loadingSvc: LoadingService,
    private store: IstdService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as { method: Method, source: 'files' | 'database' };

    this.source = state.source;

    if (state?.method) {
      this.method = state.method;
      localStorage.setItem('lastSelectedMethodTargets', JSON.stringify(state.method.targets));
    } else {
      const [name, instrument, column, im] = localStorage.getItem('lastSelectedMethod').split(' | ');
      const ionMode = im.toLowerCase() === 'positive' ? IonMode.positive : IonMode.negative;
      const targets = JSON.parse(localStorage.getItem('lastSelectedMethodTargets'));
      this.method = {name, instrument, column, ionMode, targets} as Method;
    }
  }

  ngOnInit(): void {
    this.loading$ = this.loadingSvc.loading$;
    this.loading$.pipe(
      tap(d => console.log('loading turned:', d)),
    );

    this.istds$ = this.store.loadIstdsForMethod(this.method);
    this.targetTypes$ = of(Object.values(TargetType))
      .pipe(
        map(ts => {
            return ts.filter(t => !['confirmed', 'unconfirmed', 'invalid_target'].includes(t.toLowerCase()))
              .map(t => t as TargetType);
          }
        ),
      );

  }

  addIstd(): void {
    const newIstd = {
      method: this.method.name,
      instrument: this.method.instrument,
      column: this.method.column,
      ion_mode: this.method.ionMode
    };
    const istdRef = this.addIstdDlg.open(IstdEditComponent, {
      data: {istd: newIstd, mode: 'create'},
      width: '1024px',
      height: '90%',
    });

    istdRef.afterClosed()
    .pipe(
      tap(value => console.log(`Saved: ${JSON.stringify(value)}`)),
    )
    .subscribe();
  }

  getIstds(istds): void {
    if (istds.length) {
      this.istds = istds;
    }
  }

  getSingleIstd() {
    this.store.getsingleistd()
    .pipe(
      tap(x => console.log(`from method view: ${JSON.stringify(x)}`))
    )
    .subscribe(data => alert(JSON.stringify(data)));
  }

  download_yml(): void {
    const targets = this.istds.map(istd => {
      let adduct: string;
      let inchikey: string;

      if (!istd.adduct) {
        const a = istd.identifier.match(rp.adductRegex);
        adduct = a && a.length > 1 ? a[1] : null;
      }
      if (!istd.inchi_key) {
        const i = istd.identifier.match(rp.inchiKeyRegex);
        inchikey = i && i.length > 1 ? i[1] : null;
      }

      return {
        accurateMass: istd.accurate_mass,
        adduct: adduct ? adduct : istd.adduct?.trim(),
        confirmed: true,
        identifier: istd.identifier,
        inchikey: inchikey ? inchikey : istd.inchi_key?.trim(),
        type: istd.target_type,
        requiredForCorrection: false,
        retentionTime: istd.retention_index,
        retentionTimeUnit: 'seconds',
        msms: istd.msms,
      } as YmlIstd;
    });

    const ymlMethod: YmlMethod = {
      name: this.method.name, instrument: this.method.instrument,
      column: this.method.column, ionMode: this.method.ionMode,
      targets
    };

    const data = stringify({config: [ymlMethod]});
    const fn = [ymlMethod.name, ymlMethod.instrument, ymlMethod.column, ymlMethod.ionMode].join('-');
    const filename = fn.concat('.yml').replace(/\s+/g, '_').toLowerCase();
    saveAs(new Blob([data], {type: 'text/plain;charset=utf-8'}), filename);
  }

  public back(): void {
    localStorage.removeItem('lastSelectedMethod');
    localStorage.removeItem('lastSelectedMethodTargets');
    this.router.navigate(['methods'], {relativeTo: this.route.parent});
  }

  public filterIstds(targetType: string): Observable<Istd[]> {
    return this.istds$
      .pipe(
        map(raw =>
          raw.filter(i => i.target_type as TargetType === targetType as TargetType)
        ),
        tap(istds => this.sendIstds.emit(istds)),
        shareReplay(),
      );
  }

  create_label(tt, count) {
    return `${tt} (${count})`;
  }

  md5(label: string): string {
    return Md5.hashStr(label);
  }

}
