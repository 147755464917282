import { TrackingStatus } from './tracking.status.model';
import { Acquisition } from './sample.acquisition.model';
import { Chromatography } from './sample.chromatography.model';
import { Metadata } from './sample.metadata.model';
import { Processing } from './sample.processing.model';
import { Reference } from './sample.reference.model';
import { Userdata } from './sample.userdata.model';

export class ExperimentItem {
    experiment: string;
    id: string;
    sample: string;
    status?: Array<TrackingStatus>;
    acquisition?: Acquisition;
    chromatography?: Chromatography;
    metadata: Metadata;
    processing: Processing;
    references: Array<Reference>;
    time: number;
    userdata: Userdata;
}
