import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  totalRequests = 0;
  completedRequests = 0;

  constructor(private loader: LoadingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.show(request);
    this.totalRequests++;

    return next.handle(request)
      .pipe(
        finalize(() => {
          this.completedRequests++;

          if (this.completedRequests === this.totalRequests) {
            this.loader.hide(request);
            this.completedRequests = 0;
            this.totalRequests = 0;
          }
        }),
      );
  }
}
