import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import * as XLSX from 'xlsx';
import { YmlIstd } from '../models/yml-models';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() {
  }

  loadFile(file: File, header: string[]): Observable<any> {
    return new Observable((observer: Observer<YmlIstd[]>) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const fileData = event.target.result;
        const workbook = XLSX.read(fileData, {type: 'binary', sheetStubs: true});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {raw: true, defval: ''});
        observer.next(jsonData.map(x => x as YmlIstd));
        observer.complete();
      };

      reader.onerror = (event) => {
        observer.error(event.target.error);
      };

      reader.readAsBinaryString(file);
    });
  }

  isCSVFile(file: File): boolean {
    return file.name.endsWith('.csv');
  }

  isXLSXFile(file: File): boolean {
    return file.name.endsWith('.xlsx') || file.name.endsWith('.xls');
  }
}
