import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodsComponent } from './components/methods/methods.component';
import { MethodViewComponent } from './components/method-view/method-view.component';
import { MethodEditComponent } from './components/method-edit/method-edit.component';
import { IstdsComponent } from './components/istds/istds.component';
import { IstdViewComponent } from './components/istd-view/istd-view.component';
import { IstdEditComponent } from './components/istd-edit/istd-edit.component';
import { FileImportDialogComponent } from './components/file-import-dialog/file-import-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { NgMassSpecPlotterModule } from '@wcmc/ng-mass-spec-plotter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { OverlayModule } from '@angular/cdk/overlay';
import { MethodPipe } from './utils/method.pipe';
import { StandardsMgmtRoutingModule } from './standards-mgmt-routing.module';

@NgModule({
  declarations: [
    MethodsComponent,
    MethodViewComponent,
    MethodEditComponent,
    IstdsComponent,
    IstdViewComponent,
    IstdEditComponent,
    FileImportDialogComponent,
    MethodPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    StandardsMgmtRoutingModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    NgMassSpecPlotterModule,
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
  ]
})
export class StandardsMgmtModule {
}
