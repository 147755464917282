<mat-card class="mat-card centered scrollable-content">
  <mat-card-title>
    New Method
    <p>Create a new method by adding the first Internal Standard that belongs to that method</p>
  </mat-card-title>
  <mat-card-content>
    <form class="" [formGroup]="methodForm">
      <p>
        <mat-form-field appearance="outline">
          <input matInput type="text" name="method_name" placeholder="Method name"
                 formControlName="method_name" required>
          <mat-error *ngIf="method_name.errors">Invalid method name</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="text" name="instrument" placeholder="Instrument"
                 formControlName="instrument" required>
          <mat-error *ngIf="instrument.errors">Invalid instrument name</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="text" name="column" placeholder="Column"
                 formControlName="column" required>
          <mat-error *ngIf="column.errors">Invalid column name</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Ionization Mode</mat-label>
          <mat-select placeholder="Select Ion Mode" formControlName="ionMode">
            <ng-container *ngFor="let im of ionModes">
              <mat-option [value]="im">{{ im }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </p>

      <table class="full-width">
        <tr>
          <td colspan="2">
            <mat-form-field class="full-width pad-right" appearance="outline">
              <input matInput type="text" name="name" placeholder="Compound name"
                     formControlName="name" required>
              <mat-error *ngIf="name.errors">Missing compound name</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>Target Type</mat-label>
              <mat-select placeholder="Select Target Type" formControlName="target_type">
                <ng-container *ngFor="let tp of targetTypes">
                  <mat-option [value]="tp">{{ tp }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="full-width pad-right" appearance="outline">
              <input matInput type="text" name="adduct" placeholder="Adduct"
                     formControlName="adduct" required>
              <!--              <mat-error *ngIf="adduct.errors">Missing compound name</mat-error>-->
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td class="special" colspan="2">
            <mat-form-field class="full-width pad-right" appearance="outline">
              <input matInput type="text" name="inchi_key" placeholder="InChIKey"
                     formControlName="inchi_key" required>
              <!--              <mat-error *ngIf="inchi_key.errors">Invalid InChIKey</mat-error>-->
            </mat-form-field>
          </td>
          <td class="special" colspan="2">
            <mat-slide-toggle class="mat-form-field" formControlName="hidden" name="hidden"
                              labelPosition="before">Hidden
            </mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Accurate Mass</mat-label>
          <input matInput type="number" name="accurate_mass" placeholder="Accurate Mass"
                 formControlName="accurate_mass" required>
          <mat-error *ngIf="accurate_mass.errors">Invalid Accurate Mass</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Precursor Mass</mat-label>
          <input matInput type="number" name="pre_cursors_mass" placeholder="Precursor Mass"
                 formControlName="pre_cursors_mass" required>
          <mat-error *ngIf="pre_cursors_mass.errors">Invalid Precursor Mass</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Rerention Index</mat-label>
          <input matInput type="number" name="retention_index" placeholder="Retention Index"
                 formControlName="retention_index" required>
          <mat-error *ngIf="retention_index.errors">Invalid Retention Index</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Retention Index Unit</mat-label>
          <mat-select placeholder="RI Unit" formControlName="ri_unit">
            <mat-option value="minutes">Minutes</mat-option>
            <mat-option value="seconds">Seconds</mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Splash</mat-label>
          <input matInput type="text" name="splash" placeholder="Splash" readonly
                 formControlName="splash">
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>MSMS Spectrum</mat-label>
          <textarea matInput cdkTextareaAutosize
                    cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                    name="msms" placeholder="MSMS"
                    formControlName="msms" required>
        </textarea>
          <mat-error *ngIf="msms.errors">Missing MSMS Spectrum</mat-error>
        </mat-form-field>
      </p>
      <!--
        inchi_key?: string;
        ion_mode: IonMode;
        msms: string;
        sample: string;
      -->

      <mat-card-actions>
        <button mat-raised-button color="primary"
                [disabled]="!methodForm.valid"
                (click)="saveMethod()">Add
        </button>
        <button mat-raised-button color="warn"
                (click)="close()">Cancel
        </button>
      </mat-card-actions>
    </form>
    <br/>

    <ng-container>
      <div style="max-width: 30%">
        {{ methodForm.value | json }}
      </div>
      <div style="max-width: 30%">
        Valid: {{ methodForm.valid }}
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
