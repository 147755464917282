import {Component} from '@angular/core';

@Component({
  selector: 'app-module-load-error',
  templateUrl: './module-load-error.component.html',
  styleUrls: []
})
export class ModuleLoadErrorComponent {

  constructor() { }

}
