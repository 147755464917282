import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CisServerService } from './cis-server.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [CisServerService]
})
export class CisModule {
  public static forRoot(environment: any): ModuleWithProviders<CisModule> {
    return {
      ngModule: CisModule,
      providers: [
        CisServerService,
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}
