import { TrackingStatus } from './tracking.status.model';

export class SampleHistory {
  correction_after_error_mean?: number;
  correction_after_error_range?: number;
  correction_after_max_error?: number;
  correction_after_min_error?: number;
  correction_available_standards?: number;
  correction_before_error_mean?: number;
  correction_before_error_range?: number;
  correction_before_max_error?: number;
  correction_before_min_error?: number;
  correction_failed?: boolean;
  correction_standards_found_in_percent?: number;
  history?: TrackingStatus[];
  id: string;
  job: string;
  job_state?: string;
  logGroup?: string;
  logStream?: string;
  method?: string;
  sample: string;
  selected?: boolean;
  state: string;
  timestamp: number;
  updated?: string;
}
