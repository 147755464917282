<div>
  <h3><i class="me-2 fa fa-signal"></i>Internal Standards Management</h3>
  <h4 class="text-muted">Manage the internal standards for the different Lab's methods</h4>
</div>
<br>

<div class="mx-4 method-wrapper">
  <div class="card-justified">
    <h4>Method details</h4>
    <h5>Source: {{source}}</h5>
    <div class="d-flex justify-content-end">
      <div class="d-flex method-details flex-fill justify-content-evenly">
        <div class="method-item flex-grow-1">
          <p class="method-label">Method Name:</p>
          <p class="method-value">{{ method.name }}</p>
        </div>
        <div class="method-item flex-grow-1">
          <p class="me-2 method-label">Instrument:</p>
          <p class="method-value">{{ method.instrument }}</p>
        </div>
        <div class="method-item flex-grow-1">
          <p class="method-label">Column:</p>
          <p class="method-value">{{ method.column }}</p>
        </div>
        <div class="method-item flex-grow-1">
          <p class="method-label">Ion mode:</p>
          <p class="method-value">{{ method.ionMode }}</p>
        </div>
      </div>

      <div class="buttons d-flex">
        <div class="m-2 d-flex flex-fill justify-content-evenly">
          <button mat-raised-button color="primary" (click)="addIstd()">
            <mat-icon>add_plus_outline</mat-icon>
            <span>Add ISTD</span>
          </button>

          <button class="ms-3" mat-raised-button color="accent" (click)="back()">
            <mat-icon>arrow_back</mat-icon>
            <span>Back</span>
          </button>
        </div>
        <div class="m-2">
          <button mat-raised-button color="accent" (click)="download_yml()">
            <mat-icon>download</mat-icon>
            <span>Export .yml</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr/>

  <div class="mt-4">
    <h4>Targets:</h4>
    <!--    <app-istds [method]="method" (sendIstds)="getIstds($event)"></app-istds>-->
    <mat-tab-group class="m-2">
      <mat-tab *ngFor="let tt of (targetTypes$ | async)"
               [hidden]="(filterIstds(tt) | async)?.length < 1"
               [label]="create_label(tt, (filterIstds(tt) | async)?.length)">
        <div class="d-flex align-top" *ngIf="(filterIstds(tt) | async)?.length > 0; else noTargets">
          <div class="d-flex target-list align-top">
            <div class="mb-2" *ngFor="let istd of (filterIstds(tt) | async)">
              <a id="{{md5(istd.identifier)}}"></a>
              <app-istd-view [istd]="istd"></app-istd-view>
            </div>
          </div>

          <!--          <div class="ms-2 side-list align-top">-->
          <!--            <h4>Index</h4>-->
          <!--            <p *ngFor="let istd of (filterIstds(tt) | async)">-->
          <!--              <a routerLink="./" fragment="{{md5(istd.identifier)}}">{{istd.identifier}}</a>-->
          <!--            </p>-->
          <!--          </div>-->
        </div>
        <ng-template #noTargets>
          <p class="card-justified no-targets">No targets of type: {{tt}} </p>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </div>

</div>
