import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { IonMode } from '../../models/IonMode';
import { FileService } from '../../services/file.service';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { TargetType } from '../../models/TargetType';
import { Istd } from '../../models/Istd';
import { IstdService } from '../../services/istd.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

const required = ['accurateMass', 'adduct', 'inchikey', 'msms',
  'name', 'retentionTime', 'retentionTimeUnit'];

@Component({
  selector: 'app-file-import-dialog',
  templateUrl: './file-import-dialog.component.html',
  styleUrls: ['./file-import-dialog.component.css']
})
export class FileImportDialogComponent implements OnInit {

  ionModes = Object.values(IonMode);
  targetTypes: TargetType[] = Object.values(TargetType);
  fileErrors: string[] = [];

  targets: Istd[] = [];

  form = this.fb.group({
    methodName: ['', [Validators.required]],
    instrument: ['', [Validators.required]],
    column: ['', [Validators.required]],
    ionMode: [IonMode.positive, [Validators.required]],
    fileContent: [{value: '', disabled: true}],
    targetType: TargetType.istd,
  });

  private fileContentsSubject$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public fileContents$ = this.fileContentsSubject$.asObservable();

  public istdFileContents$: Observable<Istd[]>;
  public stringFileContents$: Observable<string> = of('');

  constructor(private fb: FormBuilder,
              private fileService: FileService,
              private istdService: IstdService,
              public dialogRef: MatDialogRef<FileImportDialogComponent>,
  ) {
    dialogRef.backdropClick().subscribe(dialogRef.close);
  }

  ngOnInit(): void {

    this.istdFileContents$ = this.fileContents$
    .pipe(
      filter(x => !(x === undefined || x === null || x.length < 1)),
      switchMap(rows => {
        const splashes$: Observable<string>[] = rows.map(row => {
          return this.istdService.getSplash$(row.msms)
          .pipe(
            map(splash => ({...row, splash}))
          );
        });
        return forkJoin([...splashes$]);
      }),
      map(data => data.map(ystd =>
        this.istdService.convertJsonToIstd(ystd,
          this.form.controls.methodName.value,
          this.form.controls.instrument.value,
          this.form.controls.column.value,
          this.form.controls.ionMode.value,
        )
      )),
      map(istds => istds.map(istd => {
// "identifier"  // "accurate_mass"  // "pre_cursors_mass"  // "adduct"  // "retention_index"
// "inchi_key"  // "target_type"  // "msms"  // "confirmed"  // "splash"  // "hidden"  // "method"
// "version"  // "ion_mode"  // "sample"
          return {...istd, ...this.cleanFormData(this.form.value)};
        })
      ),
      tap(istds => this.targets = istds),
    );
    this.istdFileContents$.subscribe();

    this.stringFileContents$ = this.fileContents$
    .pipe(
      filter(x => !(x === undefined || x === null || x.length < 1)),
      map(data => {
        const cols = Object.keys(data[0]).join(',');
        const rows = data.map(i => {
          return [...Object.values(i)].join(',');
        });
        return [cols, ...rows].join('\n');
      }),
      catchError(err => {
        console.log(err);
        this.fileErrors.push(err);
        return of('');
      }),
    );
  }

  private cleanFormData(fd): any {
    // console.log(Object.keys(this.form.value));
    return {
      method: fd.methodName, instrument: fd.instrument,
      column: fd.column, ion_mode: fd.ionMode, target_type: fd.targetType
    };
  }

  // tslint:disable-next-line:typedef
  saveMethod(): any {
    const data: any = {...this.form.value, ...{targets: this.targets}};
    data.name = data.methodName;
    delete data.methodName;
    delete data.targetType;
    delete data.fileContents;
    return data;
  }

  onFileSelected(files: FileList): void {
    this.fileErrors = [];
    this.fileContentsSubject$.next([]);

    if (files && files.length > 0) {
      const file = files[0];
      if (this.fileService.isCSVFile(file) || this.fileService.isXLSXFile(file)) {
        this.loadFileContents(file);
      } else {
        console.log('Invalid file format. Please select a CSV or XLSX file.');
        this.fileErrors.push('Invalid file format. Please select a CSV or XLSX file.');
      }
    }
  }

  private loadFileContents(file: File): void {
    this.fileService.loadFile(file, required)
    .pipe(
      tap(data => {
        const missing = required.filter(x => {
          const colNames = Object.keys(data[0]).map(col => col.trim());
          return !colNames.includes(x);
        });
        if (missing.length) {
          this.fileErrors.push(`The following columns are missing: ${missing.join(',')}`);
          this.fileContentsSubject$.next([]);
          throwError(`Missing columns: ${missing}`);
        } else {
          this.fileContentsSubject$.next(data);
        }
      }),
    )
    .subscribe();
  }


  get methodName(): AbstractControl {
    return this.form.controls.methodName;
  }

  get instrument(): AbstractControl {
    return this.form.controls.instrument;
  }

  get column(): AbstractControl {
    return this.form.controls.column;
  }
}
