<div class="flex-fill istd-wrapper">
  <div class="istd-header card-justified">
    <div class="ms-4 my-2 d-flex">
      <h3 class="">{{ istd.identifier }}</h3>
      <p class="" *ngIf="istd.id">Id: {{ istd.id }}</p>
    </div>
    <mat-card-actions class="card-container-right">
      <button mat-raised-button color="primary" (click)="editIstd(istd)">
        <mat-icon class="me-2">edit</mat-icon>
        Edit
      </button>
      <button mat-raised-button color="accent" class="ms-3 me-4">
        <mat-icon class="me-2">delete</mat-icon>
        {{istd.hidden ? 'Unhide' : 'Hide'}}
      </button>
    </mat-card-actions>
  </div>

  <div class="istd-content">
    <div class="grid-container">
      <div class="field"><h4>Name:</h4><label>{{ istd.identifier }}</label></div>
      <div class="field"><h4>Accurate Mass:</h4><label>{{ istd.accurate_mass }}</label></div>
      <div class="field"><h4>Adduct:</h4><label>{{ istd.adduct }}</label></div>
    </div>
    <div class="grid-container">
      <div class="field"><h4>Retention Index:</h4><label>{{ istd.retention_index }}</label></div>
      <div class="field"><h4>Precursor Mass:</h4><label>{{ istd.pre_cursors_mass }}</label></div>
      <div class="field"><h4>Precursor Intensity:</h4><label>{{ istd.pre_cursors_intensity }}</label></div>
    </div>
    <div class="grid-container">
      <div class="field"><h4>InChIKey:</h4> <label>{{ istd.inchi_key }}</label></div>
      <div class="field"><h4>Target Type:</h4> <label>{{ istd.target_type }}</label></div>
      <div class="field"><h4>Hidden:</h4> <label>{{ istd.hidden }}</label></div>
    </div>
    <div class="grid-container">
      <div class="field"><h4>Entropy:</h4><label>{{ istd.entropy }}</label></div>
      <div class="field"><h4>Normalized entropy:</h4><label>{{ istd.normalized_entropy }}</label></div>
      <div class="field"><h4>SPLASH:</h4><label>{{ istd.splash }}</label></div>
    </div>
    <div>
      <h4>MSMS:</h4>
      <mat-tab-group>
        <!--        <mat-tab label="Plot Flot">-->
        <!--          <div>-->
        <!--            <lib-ng-mass-spec-plotter class="msms-plot" miniPlot-->
        <!--               id="{{istd.splash}}" spectrum="{{ istd.msms }}">-->
        <!--            </lib-ng-mass-spec-plotter>-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <!--        <mat-tab label="Plot ST">-->
        <!--          <div class="msms-plot" specktackleViewer-->
        <!--               id="{{istd.splash}}" spectrum="{{ istd.msms }}">-->
        <!--          </div>-->
        <!--        </mat-tab>-->
        <mat-tab label="Spectrum">
          <p>{{ istd.msms }}</p>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
