export class Metadata {
  class: string;
  species: string;
  organ: string;
  name?: string;

  constructor(cl: string, species: string, organ: string, name?: string) {
    this.class = cl;
    this.species = species;
    this.organ = organ;
    this.name = name ? name : '';
  }
}
