import { inject, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { CompoundName } from './models/compound-name.model';
import { LVData } from './models/compound-cis.model';
import { DuplicateRow } from './models/duplicate-model';
import { LastSampleVersion } from './models/library-versions.model';
import { AddedSpectrumStatus } from './models/added-spectrum-status.model';
import { SampleDataISTD } from './models/sample-data-istd.model';
import { DatabaseCount } from './models/database-count.model';
import { LibraryData } from './models/library-data.model';
import { SampleIstdCorrection } from './models/sample-istd-correction';
import { RpnaResult } from './models/rpna-result.model';
import { JobMetadata } from './models/job-metadata.model';
import { SampleSplash } from './models/sample-splash.model';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { orderBy } from 'lodash-es';
import { LibraryItem } from '../acquisition-table-generator/models/atg-types';
import { SampleCorrection } from './models/sample-correction.model';
import { BinSample } from './models/bin-sample.model';
import { SampleAnnotation } from './models/sample-annotation.model';
import { CorrectionReport } from './models/correction-report';
import { AnnotationTime } from './models/annotation-time.model';
import { MethodDataService } from './method-data.service';

@Injectable({
  providedIn: 'root'
})
export class CisServerService {

  private URL: string;
  private apiKey: string;

  //Give user ability to set margin going forward; this sets the time window for annotations at a certain RT/RI
  private _margin: number = 2.0;

  compoundPath = 'compound';
  metaPath = 'meta';

  // Storing method data when it is downloaded, currently needed in SampleEnterComponent
  private md = inject(MethodDataService);

  constructor(
    private http: HttpClient,
    @Inject('env') private env,
  ) {
    this.URL = env.CIS_URL;
    this.apiKey = localStorage.getItem('x-api-key');

    console.log(this.URL, this.apiKey);
  }

  get environment() {
    return this.env.production;
  }

  set margin(m: number) {
    this._margin = m;
  }

  get margin() {
    return this._margin;
  }

  // Set the http headers to include the API key
  private buildRequestOptions = () => {
    return {
      headers: new HttpHeaders().append('x-api-key', this.apiKey).append('Content-Type', 'application/json')
    };
  }

  // /compound/library/splash (get)
  // Get data for a specific compound
  getCompoundData(name: CompoundName, version: string): Observable<any[]> {
    const fullUrl = this.URL + '/compound/' +
      encodeURIComponent(name.pathParameters.library) + '/' +
      encodeURIComponent(name.pathParameters.splash) + '/' +
      encodeURIComponent(version);
    return this.http.get<any[]>(fullUrl, this.buildRequestOptions());
  }

  // /libraries (get)
  // Get array of databases aka libraries
  getLibraries(): Observable<LibraryItem[]> {
    const fullUrl = this.URL + '/libraries';
    return this.http.get<LibraryItem[]>(fullUrl, this.buildRequestOptions());
  }

  // Get the libraries in BinBase, and their counts
  getLibrariesCounts(): Observable<DatabaseCount[]> {
    const fullUrl = this.URL + '/libraries_counts';
    return this.http.get<DatabaseCount[]>(fullUrl, this.buildRequestOptions());
  }

  getAllLibraryData(): Observable<LibraryData[]> {
    const fullUrl = this.URL + '/libraries/all';
    console.log('getAllLibraryData()', fullUrl);
    let count = 0;
    return this.http.get<LibraryData[]>(fullUrl, this.buildRequestOptions())
    .pipe(
      map(libData => orderBy(libData, ['method', 'updated'], ['asc', 'desc'])),
      shareReplay(),
      catchError((err, caught) => {
        console.log('CIS API Error getting all libraries (versioned)', err);
        // Try 3 times so that BinView does not hang on error
        if (count < 3) {
          count++;
          return caught;
        }
        throw(err);
      }),
      tap((data: LibraryData[]) => {
        // Store current method data, currently needed in SampleEnterComponent
        this.md.data = data;
      })
    );
  }

  // /libraries/library (get)
  // Get the number of splash keys for each target type for a given library
  getLibrarySize(library: string): Observable<any[]> {
    const fullUrl = `${this.URL}/libraries/${encodeURIComponent(library)}`;
    console.dir('getLibrarySize urlString:\n' + fullUrl);
    return this.http.get<any[]>(fullUrl, this.buildRequestOptions());
  }

  // /members/library/splash/limit/offset
  // This function is unused at the moment; no tests are included for it
  getMemberData(library: string, splash: string, limit: number, offset: number): Observable<any[]> {
    const urlParts = [
      this.URL, 'compound', 'members',
      encodeURIComponent(library),
      splash,
      limit,
      offset];
    const fullUrl = urlParts.join('/');
    console.log('Full URL:', fullUrl);
    return this.http.get<any[]>(fullUrl, this.buildRequestOptions());
  }

  // /compound/name/{library}/{splash}/{version}/{name}/{identifiedBy} (POST)
  // Enter identifying data for the compound into CIS
  addIdentifyingData(library: string, splash: string, version: string, suggestedName: string, identifiedBy: string,
                     comment: string, inchiKey: string) {
    const urlParts = [
      this.URL, 'compound', 'name',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(suggestedName),
      encodeURIComponent(identifiedBy)
    ];
    const fullUrl = urlParts.join('/');
    const payLoad = JSON.stringify({comment, inchi_key: inchiKey});
    console.dir('Full URL: ' + fullUrl + ' \n' + payLoad);
    return this.http.post<any>(fullUrl, payLoad, this.buildRequestOptions());
  }

  // /compound/adduct/{library}/{splash}/{version}/{identifiedBy}/{name}
  // Enter suggested adduct data for the compound into CIS
  addAdductData(library: string, splash: string, version: string, identifiedBy: string, suggestedAdduct: string, comment: string) {
    const urlParts = [
      this.URL, 'compound', 'adduct',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(identifiedBy),
      encodeURIComponent(suggestedAdduct)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Add adduct full URL: ' + fullUrl);
    return this.http.post<any>(fullUrl, JSON.stringify({comment}), this.buildRequestOptions());
  }

  // /compound/comment/{library}/{splash}/{identifiedBy}
  // Add any additional comments to a compound
  addComment(library: string, splash: string, version: string, identifiedBy: string, comment: string) {
    const urlParts = [
      this.URL, 'compound', 'comment',
      encodeURIComponent(library),
      splash,
      encodeURIComponent(version),
      encodeURIComponent(identifiedBy)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Add comment full URL: ' + fullUrl);
    return this.http.post(fullUrl, JSON.stringify({comment}), this.buildRequestOptions());
  }

  // Get metadata entries for a compound/bin
  getMetaData(library: string, splash: string, version: string, name?: string, limit?: number, offset?: number) {
    const urlParts = [
      this.URL,
      this.compoundPath,
      this.metaPath,
      encodeURIComponent(library.trim()),
      encodeURIComponent(splash.trim()),
      encodeURIComponent(version.trim())
    ];
    const options = [];
    if (name) {
      options.push(`name=${encodeURIComponent(name.trim())}`);
    }
    if (limit) {
      options.push(`limit=${limit}`);
    }
    if (offset) {
      options.push(`offset=${offset}`);
    }
    const fullUrl = urlParts.join('/') + (options.length > 0 ? '?' + options.join('&') : '');
    console.dir('getMetaData full url: ' + fullUrl);
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // /compound/meta/{library}/{splash}/{identifiedBy}/{name}/{value}
  // Add identifiers like inchi key, KEGG, etc.
  //  inchi_key, inchi_code, kegg, formula, pubchem_id
  addMetaData(library: string, splash: string, version: string, identifiedBy: string, name: string, value: string) {
    const urlParts = [
      this.URL, 'compound', 'meta',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(identifiedBy),
      name,
      encodeURIComponent(value)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL addMetaData: ' + fullUrl);
    return this.http.post<any>(fullUrl, '', this.buildRequestOptions());
  }

  // /compound/meta/reject/{meta_id}/{compound_id}/{rejected}
  rejectMetaData(metaId: string, compoundId: string, rejected: string) {
    const urlParts = [this.URL, 'compound', 'meta', 'reject', metaId, compoundId, rejected];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL rejectMetaData: ' + fullUrl);
    return this.http.put(fullUrl, '', this.buildRequestOptions());
  }

  // Register and promote meta all at once
  registerPromoteMeta(method: string, splash: string, version: string, identifiedBy: string, name: string, value: string): Observable<any> {
    const urlParts = [
      this.URL,
      'compound', 'meta', 'rpm',
      encodeURIComponent(method),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(identifiedBy),
      encodeURIComponent(name),
      encodeURIComponent(value)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL registerPromoteMeta: ' + fullUrl);
    return this.http.post<any>(fullUrl, '', this.buildRequestOptions());
  }

  // DEPRECATED w/new API, use rejection
  // /compound/identify/{library}/{splash}/{identifiedBy}/{name} (DELETE)
  // Delete identifying data for the compound into CIS
  deleteIdentifyingData(library: string, splash: string, identifiedBy: string, suggestedName: string) {
    const urlParts = [
      this.URL, 'compound', 'identify',
      encodeURIComponent(library),
      splash,
      encodeURIComponent(identifiedBy),
      encodeURIComponent(suggestedName)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL: ' + fullUrl);
    return this.http.delete(fullUrl, this.buildRequestOptions());
  }

  // compound/name/reject/{name_id}/{compound_id}/{rejected}
  // Reject identifying data, change the flag (hold on)
  rejectIdentifyingData(nameId: string, compoundId: string, rejected: string) {
    const urlParts = [this.URL, 'compound', 'name', 'reject', nameId, compoundId, rejected];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL: ' + fullUrl);
    return this.http.put(fullUrl, '', this.buildRequestOptions());
  }

  // compound/name/p/{library}/{splash}/{version}/{assoc_id}
  // Promote suggested name to the preferred_name with a PUT
  promotePrimaryName(library: string, splash: string, version: string, newId: string) {
    const urlParts = [
      this.URL, 'compound', 'name', 'p',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(newId)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Full URL:\n' + fullUrl);
    return this.http.put(fullUrl, '', this.buildRequestOptions());
  }

  // /compound/adduct/{library}/{splash}/{identifiedBy}/{name}
  // Delete adduct data for a compound
  deleteAdductData(library: string, splash: string, identifiedBy: string, adductToDelete: string) {
    const urlParts = [
      this.URL, 'compound', 'adduct',
      encodeURIComponent(library),
      splash,
      encodeURIComponent(identifiedBy),
      encodeURIComponent(adductToDelete)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Delete adducts full URL: ' + fullUrl);
    return this.http.delete(fullUrl, this.buildRequestOptions());
  }

  // /compound/adduct/{library}/{splash}/{version}/{identifiedBy}/{name}
  //  identifiedBy can be any string, using 'user' here
  promoteAdductName(library: string, splash: string, version: string, newAdduct: string) {
    const urlParts = [
      this.URL, 'compound', 'adduct',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      'user',
      encodeURIComponent(newAdduct)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('Promote adduct full URL: ' + fullUrl);
    return this.http.put<any>(fullUrl, '', this.buildRequestOptions());
  }

  // Register (suggest) Promote Name Adduct
  rpna(library: string, splash: string, version: string,
       name: string, adduct: string, identifiedBy: string, comment: string): Observable<RpnaResult> {
    const urlParts = [
      this.URL,
      'compound', 'rpna',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version),
      encodeURIComponent(name),
      encodeURIComponent(adduct),
      encodeURIComponent(identifiedBy)
    ];
    return this.http.post<RpnaResult>(urlParts.join('/'), JSON.stringify({comment}), this.buildRequestOptions());
  }

  // compound/adduct/reject/{adduct_id}/{compound_id}/{rejected}
  rejectAdductName(adductId: string, compoundId: string, rejected: string) {
    const urlParts = [this.URL, 'compound', 'adduct', 'reject', adductId, compoundId, rejected];
    const fullUrl = urlParts.join('/');
    console.dir('Reject adduct full URL: ' + fullUrl);
    return this.http.put<any>(fullUrl, '', this.buildRequestOptions());
  }

  getAdductList(mode: string) {
    const pmode = encodeURIComponent(mode);
    return this.http.get<string[]>(this.URL + '/adducts/' + pmode, this.buildRequestOptions())
    .pipe(
      tap(response => console.log('API got adducts', response)),
      shareReplay(),
      catchError(err => {
        console.log('CIS API, error getting adducts', err);
        return of(Array<string>());
      })
    );
  }

  // /configurations/{method}/{version}/{object_type}/{value} get
  // Get configurations for a sample or target (compound)
  getConfigurations(method: string, version: string, objectType: string, value: string): Observable<any> {
    const urlParts = [
      this.URL, 'configurations',
      encodeURIComponent(method),
      encodeURIComponent(version),
      objectType,
      encodeURIComponent(value)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('getConfigurations full URL: ' + fullUrl);
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // /profiles/{method}/{version}/{object_type}/{value} get
  // Get profiles for a sample or target (compound)
  getProfiles(method: string, version: string, objectType: string, value: string): Observable<any> {
    const urlParts = [
      this.URL, 'profiles',
      encodeURIComponent(method),
      encodeURIComponent(version),
      objectType,
      encodeURIComponent(value)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('getProfiles full URL: ' + fullUrl);
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // splashes/{library}/{version}/{targetType}?limit={limit}&offset={offset}&order_by={orderBy}&direction={direction}
  //  &identified={idOnly} + options
  //  orderBy choices: id, ri, pmz, name, adduct
  //  Property default values:
  //  orderBy = 'id'
  //  targetType = 'CONFIRMED'
  //  limit = 10
  //  offset = 0
  //  direction = 'asc'
  //  idOnly 'True'/'False'
  getOrderedSplashKeys(library: string, version: string, targetType: string, limit: number, offset: number,
                       orderBy: string, direction: boolean | 'desc' | 'asc', idOnly: string,
                       options: string): Observable<any> {
    const urlString = `${this.URL}/splashes/${encodeURIComponent(library)}/${encodeURIComponent(version)}/${targetType}` +
      `?limit=${limit.toString()}&offset=${offset.toString()}&order_by=${orderBy}&direction=${direction}&identified=${idOnly}${options}`;
    // Testing with just a library call
    // const urlString = `${this.URL}/compounds/${library}`;
    console.dir('getOrderedSplashKeys urlString:\n' + urlString);
    return this.http.get<any[]>(urlString, this.buildRequestOptions());
  }

  // compounds/{library}/{version}/{targetType}?limit={limit}&offset={offset}&order_by={orderBy}&direction={direction}
  // &similar will go in the options
  getOrderedCompounds(library: string, version: string, targetType: string, limit: number, offset: number, options: string[]): Observable<any> {
    const urlParts = [
      this.URL,
      'compounds',
      encodeURIComponent(library),
      encodeURIComponent(version),
      encodeURIComponent(targetType),
      `?limit=${limit}&offset=${offset}`
    ];
    const urlString = urlParts.join('/') + (!!options.length ? '&' + options.join('&') : '');
    console.dir('getOrderedCompounds urlString:\n' + urlString);
    return this.http.get<any[]>(urlString, this.buildRequestOptions());
  }

  // https://test-api.metabolomics.us/cis/spectrum/199
  getSpectrumStatus(targetId: string): Observable<any[]> {
    const urlString = `${this.URL}/spectrum/${targetId}`;
    console.dir('getSpectrumStatus urlString:\n' + urlString);
    return this.http.get<any[]>(urlString, this.buildRequestOptions());
  }

  // https://test-api.metabolomics.us/cis/spectrum/199?identifiedBy=test-user&clean=false
  addSpectrumStatus(targetId: string, identifiedBy: string, clean: string): Observable<AddedSpectrumStatus> {
    const urlString = `${this.URL}/spectrum/${targetId}?identifiedBy=${encodeURIComponent(identifiedBy)}&clean=${clean}`;
    console.dir('addSpectrumStatus urlString:\n' + urlString);
    return this.http.post<AddedSpectrumStatus>(urlString, '', this.buildRequestOptions());
  }

  // https://test-api.metabolomics.us/cis/spectrum/199?identifiedBy=test-user
  deleteSpectrumStatus(targetId: string, identifiedBy: string): Observable<any> {
    const urlString = `${this.URL}/spectrum/${targetId}?identifiedBy=${encodeURIComponent(identifiedBy)}`;
    console.dir('deleteSpectrumStatus urlString:\n' + urlString);
    return this.http.delete(urlString, this.buildRequestOptions());
  }

  // libraries/profiles/{library}
  getLibraryProfiles(library: string): Observable<any> {
    const urlParts = [this.URL, 'libraries/profiles', encodeURIComponent(library)];
    const fullUrl = urlParts.join('/');
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // libraries/unique_profiles/{library}/{version}
  getUniqueProfiles(library: string, version: string): Observable<any> {
    const urlString = `${this.URL}/libraries/unique_profiles/${encodeURIComponent(library)}/${encodeURIComponent(version)}`;
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  // libraries/versions/{library}
  getLibraryVersions(library: string): Observable<any> {
    const urlString = `${this.URL}/libraries/versions/${encodeURIComponent(library)}`;
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  // Most recent version of library
  // libraries/last_version/{library}
  getLastLibraryVersion(library: string): Observable<any> {
    const urlString = `${this.URL}/libraries/last_version/${encodeURIComponent(library)}`;
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  getLastSampleVersion(sample: string, all?: boolean): Observable<LastSampleVersion[]> {
    let urlString = `${this.URL}/samples/last_version/${encodeURIComponent(sample)}`;
    urlString += all ? '?all' : '';
    return this.http.get<LastSampleVersion[]>(urlString, this.buildRequestOptions());
  }

  // Getting duplicates

  getLibraryDuplicates(library: string): Observable<any> {
    const urlString = `${this.URL}/compound/duplicates/list/${encodeURIComponent(library)}`;
    console.log('getAllDuplicates url: ', urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  getCompoundDuplicates(library: string, splash: string, version: string): Observable<DuplicateRow[]> {
    const urlParts = [
      this.URL, 'compound', 'duplicates',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('getCompoundDuplicates full url: ' + fullUrl);
    return this.http.get<DuplicateRow[]>(fullUrl, this.buildRequestOptions());
  }

  getAggregateDuplicates(library: string, version: string, limit: number = 100, offset: number = 0): Observable<any> {
    const urlString = `${this.URL}/compound/duplicates/aggregate/${encodeURIComponent(library)}/${encodeURIComponent(version)}` +
      `?limit=${limit}&offset=${offset}`;
    console.dir('getAggregateDuplicates full url: ' + urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  getParent(library: string, splash: string, version: string): Observable<any> {
    const urlString = `${this.URL}/compound/parent/${encodeURIComponent(library)}/${splash}/${encodeURIComponent(version)}`;
    console.dir('getParent url: ' + urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  getCompoundFragments(library: string, splash: string, version: string): Observable<DuplicateRow[]> {
    const urlParts = [
      this.URL, 'compound', 'fragments',
      encodeURIComponent(library),
      encodeURIComponent(splash),
      encodeURIComponent(version)
    ];
    const fullUrl = urlParts.join('/');
    console.dir('getCompoundFragments full url: ' + fullUrl);
    return this.http.get<DuplicateRow[]>(fullUrl, this.buildRequestOptions());
  }

  getAggregateFragments(library: string, version: string, limit: number = 100, offset: number = 0): Observable<any> {
    const urlParts = [
      this.URL,
      'compound',
      'fragments',
      'aggregate',
      encodeURIComponent(library),
      encodeURIComponent(version)
    ];
    const fullUrl = urlParts.join('/') + `?limit=${limit}&offset=${offset}`;
    console.dir('getAggregateFragments full url: ' + fullUrl);
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // valid=true - exclude libraries/versions or INVALID_TARGET bins
  getLibraryVersionFromSplash(splash: string, valid?: boolean): Observable<LVData[]> {
    let urlString = `${this.URL}/compound/lv/${splash}`;
    urlString += valid ? '?valid' : '';
    return this.http.get<LVData[]>(urlString, this.buildRequestOptions());
  }

  // entropies/{library}/{version}/{targetType}
  getEntropies(library: string, version: string, targetType: string): Observable<any> {
    const urlString = `${this.URL}/entropies/` +
      `${encodeURIComponent(library)}/${encodeURIComponent(version)}/${targetType}`;
    console.dir('getEntropies url: ' + urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  getISTD(): Observable<any> {
    const urlString = `${this.URL}/istd`;
    console.log('getISTD url: ', urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  // Get data for a bin ID, I think this is used in Diego's Library Manager
  getCompoundISTD(id: number): Observable<any> {
    const urlString = `${this.URL}/istd/${id}`;
    console.log('getCompoundISTD url: ', urlString);
    return this.http.get<any>(urlString, this.buildRequestOptions());
  }

  // Get ISTD data for a sample
  getSampleDataISTD(method: string, version: string, sample: string): Observable<SampleDataISTD[]> {
    const urlString = `${this.URL}/samples/istds/` +
      `${encodeURIComponent(method)}/${encodeURIComponent(version)}/${encodeURIComponent(sample)}`;
    console.dir('getSampleDataISTD url: ' + urlString);
    return this.http.get<SampleDataISTD[]>(urlString, this.buildRequestOptions());
  }

  getSampleISTDCorrection(method: string, version: string, sample: string): Observable<SampleIstdCorrection[]> {
    const urlParts = [
      this.URL,
      'samples',
      'correction',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(sample)
    ];
    return this.http.get<SampleIstdCorrection[]>(urlParts.join('/'), this.buildRequestOptions());
  }

  getCorrectionReport(method: string, version: string, sample: string): Observable<CorrectionReport> {
    const urlParts = [
      this.URL,
      'samples',
      'report',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(sample)
    ];
    return this.http.get<CorrectionReport>(urlParts.join('/'), this.buildRequestOptions());
  }

  getBinCorrectionReport(method: string, version: string, splash: string): Observable<CorrectionReport> {
    const urlParts = [
      this.URL,
      'compound',
      'report',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(splash)
    ];
    return this.http.get<CorrectionReport>(urlParts.join('/'), this.buildRequestOptions());
  }

  // Get list of splash keys of bins associated with a sample, valid=true - exclude INVALID_TARGET bins
  getSplashesFromSample(method: string, version: string, sample: string, valid?: boolean): Observable<SampleSplash[]> {
    const urlParts = [
      this.URL,
      'samples',
      'splashes',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(sample)
    ];
    let urlString = urlParts.join('/') + (valid ? '?valid' : '');
    console.dir('getSplashesFromSample url: ' + urlString);
    return this.http.get<SampleSplash[]>(urlString, this.buildRequestOptions());
  }

  getAllIstds(): Observable<any> {
    const urlParts = [this.URL, 'istd'];
    const fullUrl = urlParts.join('/');
    return this.http.get<any>(fullUrl, this.buildRequestOptions());
  }

  // Arrays of jobs associated with an experiment
  getExperimentJobs(experiment: string): Observable<string[]> {
    let fullPath = `${this.URL}/experiment_jobs/${encodeURIComponent(experiment)}`;
    return this.http.get<string[]>(fullPath, this.buildRequestOptions());
  }

  getJobRequiredMetadata(job: string, offset?: number, limit?: number): Observable<JobMetadata[]> {
    let path = `${this.URL}/jobs/meta/${encodeURIComponent(job)}`;
    let qParams = [(offset ? 'offset=' + offset : ''), (limit ? 'limit=' + limit : '')]
    .filter(val => val !== '');
    if (qParams.length) {
      path = path + '?' + qParams.join('&');
    }
    return this.http.get<JobMetadata[]>(path, this.buildRequestOptions());
  }

  getBinSamples(method: string, version: string, splash: string): Observable<BinSample[]> {
    const urlParts = [
      this.URL,
      'samples',
      'bss',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(splash)
    ];
    const fullURL = urlParts.join('/');
    return this.http.get<BinSample[]>(fullURL, this.buildRequestOptions());
  }

  // List of annotations for bins/samples/jobs
  getSampleAnnotationData(method: string, version: string, params: string[], offset?: number, limit?: number): Observable<SampleAnnotation[]> {
    const urlParts = [
      this.URL,
      'samples',
      'sad',
      encodeURIComponent(method),
      encodeURIComponent(version)
    ];
    let qParams = [(offset ? 'offset=' + offset : ''), (limit ? 'limit=' + limit : ''), ...params]
      .filter(val => val !== '');
    let path = urlParts.join('/') + (!!qParams.length ? '?' + qParams.join('&') : '');
    // console.log('getSampleAnnotationData URL: ', path);
    return this.http.get<SampleAnnotation[]>(path, this.buildRequestOptions());
  }

  // Returns annotations for a sample, supports filtering by RT/RI window
  getAnnotationsBySample(method: string, version: string, sample: string, options: string[]): Observable<AnnotationTime[]> {
    const urlParts = [
      this.URL,
      'annBySample',
      encodeURIComponent(method),
      encodeURIComponent(version),
      encodeURIComponent(sample),
    ];
    let path = urlParts.join('/');
    if (!!options.length) {
      path += '?' + options.join('&');
    }
    // console.log('getAnnotationsBySample URL: ', path);
    return this.http.get<AnnotationTime[]>(path, this.buildRequestOptions());
  }

}
