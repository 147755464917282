import { inject, Injectable } from '@angular/core';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  router = inject(Router);
  authService = inject(AuthenticatorService);

  constructor() {
  }

  onAuthEvent(data) {
    console.log('Auth Event:', data.payload.event);
    if (data.payload.event === 'signedIn') {
      localStorage.setItem('x-api-key', data.payload.data.userId);
      // Add the API key to the user's attributes
      fetchUserAttributes()
      .then(attributes => {
        localStorage.setItem('user', attributes.name + ' ' + attributes.family_name);
        localStorage.setItem('email', attributes.email);
        console.log('loaded attributes');
      })
      .catch(err => {
        console.error('Can\'t get user attributes', err);
        localStorage.clear();
        this.router.navigate(['/']);
        this.authService.signOut();
      });

      fetchAuthSession()
      .then(session => {
        const pl = session.tokens.idToken.payload;
        const user_groups = Array.isArray(pl['cognito:groups']) ? pl['cognito:groups'] : [];
        localStorage.setItem('user_groups', user_groups.toString());
        console.log('loaded usergroups');

      })
      .catch(err => {
        console.error('Can\'t get Auth Session.', err);
        localStorage.clear();
        this.router.navigate(['/']);
        this.authService.signOut();
      });

    }

    if (data.payload.event === 'signedOut') {
      console.log('Hub Auth event signOut');
      this.router.navigate(['/']).then(x => console.log('navigated', x));
    }
  }

  onSignout() {
    console.log('clear localstore');

    localStorage.clear();
    this.authService.signOut();
  }

  getCurrentUser(): User {
    const groups = localStorage.getItem('user_groups').split(',');
    return {
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
      userId: localStorage.getItem('x-api-key'),
      isUser: groups.includes('Users'),
      isAdmin: groups.includes('Administrators'),
    } as User;
  }


}
