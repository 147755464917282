import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontPageComponent } from './front-page/front-page.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/frontPage', pathMatch: 'full'
  },
  {
    path: 'frontPage', component: FrontPageComponent
  },
  {
    path: 'acquisition',
    loadChildren: () => import('./acquisition-table-generator/acquisition-table-generator.module')
    .then(m => m.AcquisitionTableGeneratorModule)
  },
  {
    path: 'experiment',
    loadChildren: () => import('./experiment/experiment.module').then(m => m.ExperimentModule)
  },
  {
    path: 'scheduler',
    loadChildren: () => import('./scheduler/scheduler.module').then(m => m.SchedulerModule)
  },
  {
    path: 'binview',
    loadChildren: () => import('./binview/bin-view.module').then(m => m.BinViewModule)
  },
  {
    path: 'standards',
    loadChildren: () => import('./standards-mgmt/standards-mgmt.module').then(m => m.StandardsMgmtModule)
  },
  {
    path: 'utility',
    loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule)
  },
  {
    path: 'monitor',
    loadChildren: () => import('./qcmonitor/qc-monitor.module').then(m => m.QcMonitorModule)
  },
  {
    path: 'audit',
    loadChildren: () => import('./audit-logs/audit-logs.module').then(m => m.AuditLogsModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then(m => m.TestModule)
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
