import { Inject, inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../../environments/ienvironment';
import { finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProfileModel } from '../model/profile-model';
import { CategoryFullModel } from '../model/category-full-model';

@Injectable({
  providedIn: 'root'
})

export class AggregationService {
  http = inject(HttpClient);

  private baseUrl = this.env.SETTINGS_URL;

  private headers = new HttpHeaders()
  .append('x-api-key', localStorage.getItem('x-api-key'));

  constructor(
    @Inject('env') private env: Environment,
  ) {
  }

  getCategoryListPlain$(): Observable<string[]> {
    return this.http.get(`${this.baseUrl}/category/list`, {headers: this.headers})
    .pipe(
      tap(console.log),
      finalize(() => console.log('DONE cats plain'))
    );
  }

  getCategoryListFull$(): Observable<CategoryFullModel[]> {
    return this.http.get(`${this.baseUrl}/category/full`, {headers: this.headers})
    .pipe(
      tap(console.log),
      finalize(() => console.log('DONE cats full'))
    );
  }

  getProfilesForCategory$(category: string): Observable<ProfileModel[]> {
    const cleanCat = encodeURIComponent(category);
    return this.http.get(`${this.baseUrl}/category/${cleanCat}/profiles`, {headers: this.headers})
    .pipe(
      tap(console.log),
      finalize(() => console.log('DONE profiles'))
    );
  }
}
