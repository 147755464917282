export class TrackingStatus {
  time: number;
  value: string;
  priority: number;
  fileHandle?: string;
  logGroup?: string;
  logStream?: string;
  optional?: any;
  reason?: string;
}
