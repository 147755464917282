export enum Statuses {
  dropped = 0,
  entered = 10,
  registering = 15,
  creating = 20,
  created = 30,
  acquired = 100,
  discovered_raw_data = 110,
  storing_raw_data = 125,
  stored_raw_data = 150,
  converted_raw_data = 175,
  converted = 200,
  uploaded_raw = 250,
  processing_required = 275,
  scheduling = 299,
  scheduled = 300,
  scheduled_to_run = 350,
  processing = 400,
  deconvoluted = 410,
  corrected = 420,
  annotated = 430,
  quantified = 440,
  replaced = 450,
  compounds_generated_begin = 475,
  compounds_generated_end = 478,
  compounds_identified_begin = 480,
  compounds_identified_end = 485,
  exporting = 499,
  exported = 500,
  uploaded_result = 501,
  'waiting for sample tracking synchronization' = 505,
  aggregation_required = 510,
  aggregating_scheduling = 520,
  aggregating_scheduled = 530,
  aggregating_scheduled_to_run = 540,
  aggregating = 550,
  aggregated = 590,
  aggregated_and_uploaded = 591,
  notified = 595,
  finished = 600,
  failed = 900
}