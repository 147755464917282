// * = Request queries postgres and testing in Angular
// - Not needed to change anything
// Marked for deletion
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { SampleData } from './model/sample.model';
import { SampleHistory } from './model/sample-history.model';
import { ResultData } from './model/result.model';
import { TrackingData } from './model/tracking.model';

import { JobData } from './model/job.model';
import { JobSample } from './model/job-sample.model';

import { MessageService } from './message.service';
import { JobsKnown } from './model/jobs-known.model';
import { JobStatus } from './model/job.status.model';
import { Statuses } from './model/statuses.model';
import { ResultLink } from './model/result.link.model';
import { shareReplay, tap } from 'rxjs/operators';
import { ExperimentItem } from './model/experiment.item.model';
import { JobBin } from './model/job.bin.model';
import { JobSampleCount } from '../public-api';
import { SampleStateData } from './model/sample.state.data.model';
import { SampleStateCount } from './model/sample.state.count.model';

@Injectable()
export class StasisService {

  private stasisURL: string;
  private schedulerURL: string;

  private URL: string;
  private GOJOBS_URL = this.env.GOJOBS_URL;
  private apiKey: string;

  private trackingPath = 'tracking';
  private resultPath = 'result';
  private acquisitionPath = 'acquisition';
  private experimentPath = 'experiment';
  private experimentJobsPath = 'experiment_jobs';
  private statusPath = 'status';
  private mzmlPath = 'mzml';

  constructor(private http: HttpClient,
              @Inject('env') private env,
              private messageService: MessageService) {
    this.URL = env.STASIS_URL;
    this.schedulerURL = env.SCHEDULER_URL;
    this.apiKey = localStorage.getItem('x-api-key');
  }

  private buildRequestOptions = () => {
    return {
      headers: new HttpHeaders().append('x-api-key', this.apiKey).append('Content-Type', 'application/json')
    };
  };

  // Scheduler functions

  // *
  getJobData(jobId: string, offset?: number, limit?: number, sampleTable?: boolean, method?: string): Observable<SampleHistory[]> {
    const partialURL = `${this.URL}/job/${encodeURIComponent(jobId)}`;
    const queryParams = [
      !!offset ? `offset=${offset}` : '',
      !!limit ? `limit=${limit}` : '',
      !!sampleTable ? `sample_table` : '',
      !!method ? `method=${encodeURIComponent(method)}` : ''
    ];
    const queryString = queryParams.filter(val => !!val).join('&');
    const fullURL = partialURL + (!!queryString ? `?${queryString}` : '');
    console.log('fullURL: ', fullURL);
    return this.http.get<any[]>(fullURL, this.buildRequestOptions());
  }

  // *
  getKnownJobs(): Observable<JobsKnown> {
    return this.http.get<JobsKnown>(`${this.URL}/jobs`, this.buildRequestOptions());
  }

  // *
  getJobsFromSample(sample: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.URL}/jobs/${encodeURIComponent(sample)}`, this.buildRequestOptions());
  }

  // From array of jobs, get number of samples for each
  getJobSampleCounts(jobs: string[]): Observable<JobSampleCount[]> {
    const job_string = encodeURIComponent(jobs.join(','));
    return this.http.get<any[]>(`${this.URL}/jobs/sample_counts?job=${job_string}`, this.buildRequestOptions());
  }

  // This endpoint still exists, just unused; BinView uses getResultLink/getMzmlLink with downloadFromPresignedUrl instead
  // Get result file, will fail if file is too large
  getResultData(jobId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.URL}/job/${this.resultPath}/download/${encodeURIComponent(jobId)}`, this.buildRequestOptions());
  }

  // -
  // Get an object with a link to the result file
  getResultLink(jobId: string): Observable<ResultLink> {
    return this.http.get<ResultLink>(`${this.URL}/job/${this.resultPath}/download2/${encodeURIComponent(jobId)}`, this.buildRequestOptions());
  }

  // -
  getMzmlLink(sample: string, fileType?: string): Observable<ResultLink> {
    let urlString = `${this.URL}/job/${this.mzmlPath}/download/${encodeURIComponent(sample)}`;
    urlString += fileType ? `?file_type=${fileType}` : '';
    return this.http.get<ResultLink>(urlString, this.buildRequestOptions());
  }

  // -
  // Download data directly from presigned_url
  downloadFromPresignedUrl(presigned_url: string): Observable<any> {
    return this.http.get(presigned_url, {responseType: 'blob'});
  }

  // *
  // Use stasis url
  getJobStatus(jobId: string): Observable<JobStatus> {
    return this.http.get<JobStatus>(`${this.URL}/job/status/${encodeURIComponent(jobId)}`, this.buildRequestOptions());
  }

  // Use stasis url
  /* DEPRECATED. Please use storeJobNew */
  storeJob(job: JobData): Observable<any> {
    return this.http.post<any>(`${this.URL}/job/store`, job, this.buildRequestOptions());
  }

  storeJobNew(job: JobData): Observable<void> {
    return this.http.post<void>(`${this.GOJOBS_URL}/job/${job.id}`, job, this.buildRequestOptions());
  }

  // Use stasis url
  /* DEPRECATED. Please use storeJobSampleNew */
  storeSample(sample: JobSample): Observable<any> {
    return this.http.post<any>(`${this.URL}/job/sample/store`, sample, this.buildRequestOptions());
  }

  storeJobSampleNew(sample: JobSample): Observable<void> {
    return this.http.post<void>(`${this.GOJOBS_URL}/job/sample/store`, sample, this.buildRequestOptions());
  }

  // Use scheduler url
  scheduleJob(job: JobData): Observable<any> {
    console.log('SCHEDULE JOB ', this.schedulerURL);
    return this.http.put<any>(`${this.schedulerURL}/job/schedule/${encodeURIComponent(job.id)}`, job, this.buildRequestOptions());
  }

  // Use scheduler url
  aggregateJob(jobId: string) {
    return this.http.post<any>(`${this.schedulerURL}/job/aggregate/${encodeURIComponent(jobId)}`, '', this.buildRequestOptions());
  }

  updateJobProfile(job: JobData): Observable<void> {
    return this.http.put<void>(`${this.GOJOBS_URL}/job/${job.id}`, job, this.buildRequestOptions());
  }

  // Use scheduler url
  //  Schedule one sample of a job
  scheduleSample(jobId: string, sample: string, labels?: string[]) {
    return this.http.put<any>(`${this.schedulerURL}/job/schedule/${encodeURIComponent(jobId)}/${encodeURIComponent(sample)}`,
      !!labels ? {labels} : '',
      this.buildRequestOptions()
    );
  }

  // *
  // Added 10/20/20 - 'tracking' may not be the same as this.trackingPath
  getJobTracking(jobId: string, sample: string): Observable<any> {
    return this.http.get<TrackingData>(`${this.URL}/job/tracking/${encodeURIComponent(jobId)}/${encodeURIComponent(sample)}`, this.buildRequestOptions());
  }

  getExperimentJobs(experiment: string): Observable<string[]> {
    let fullPath = `${this.URL}/${this.experimentJobsPath}/${encodeURIComponent(experiment)}`;
    return this.http.get<string[]>(fullPath, this.buildRequestOptions());
  }

  // MiniX experiments associated with a job
  getExperimentsFromJob(job: string): Observable<string[]> {
    let path = `${this.URL}/efj/${job}`;
    return this.http.get<string[]>(path, this.buildRequestOptions());
  }

  ////////////////////////////////////

  updateJobState$(job: string, state: string) {
    let path = `${this.GOJOBS_URL}/job/update/${encodeURIComponent(job)}/${encodeURIComponent(state)}`;
    console.log('updateJobState path: ', path);
    return this.http.put<any>(path, job, this.buildRequestOptions()).pipe(
      tap(x => console.log('stasis.updateJobState response: ', x))
    );
  }

  // *
  getTracking(sample: string): Observable<TrackingData> {
    return this.http.get<TrackingData>(`${this.URL}/${this.trackingPath}/${encodeURIComponent(sample)}`, this.buildRequestOptions());
  }

  // Sample state data from sample_state LC-BinBase table
  //  If user provides method and version, there is a join with table sample
  //  Otherwise, there is a join with sample_metadata to get content object which has method data via the chromatography property
  getSampleStateData(options: string[]): Observable<SampleStateData[]> {
    let path = `${this.URL}/sampleStateData`;
    if (!!options.length) {
      path += '?' + options.join('&');
    }
    return this.http.get<SampleStateData[]>(path, this.buildRequestOptions());
  }

  getSampleStateCounts(options: string[]): Observable<SampleStateCount[]> {
    let path = `${this.URL}/sampleStateCounts`;
    if (!!options.length) {
      path += '?' + options.join('&');
    }
    return this.http.get<SampleStateCount[]>(path, this.buildRequestOptions());
  }

  addTracking(sample: string, status: string): Observable<TrackingData> {
    return this.http.post<TrackingData>(`${this.env.BASEURL}/samples/status`, {
      sample,
      status
    }, this.buildRequestOptions());
  }

  // -
  checkResultExist(sample: string): Observable<ResultData> {
    return this.http.head<ResultData>(`${this.URL}/${this.resultPath}/${encodeURIComponent(sample)}`, this.buildRequestOptions());
  }

  addResult(data: ResultData): Observable<ResultData> {
    return this.http.post<ResultData>(`${this.URL}/${this.resultPath}`, data, this.buildRequestOptions());
  }

  // *
  getAcquisition(sample: string): Observable<SampleData> {
    return this.http.get<SampleData>(`${this.URL}/${this.acquisitionPath}/${encodeURIComponent(sample)}`, this.buildRequestOptions());
  }

  createAcquisition(data: SampleData): Observable<SampleData> {
    return this.http.post<SampleData>(`${this.env.BASEURL}/samples/metadata`, data, this.buildRequestOptions());
  }

  // DEPRECATED - using hard-coded values in projects/stasis/src/lib/model statuses.model.ts
  getStatuses(): Observable<Statuses> {
    return this.http.get<Statuses>(`${this.URL}/${this.statusPath}`, this.buildRequestOptions());
  }

  // *
  getExperiment(minix: number, offset?: number, limit?: number): Observable<ExperimentItem[]> {
    // By default, API returns data with offset = 0, and limit = 100

    // 2024/12/06 - switching to the previous experiment path because the one below does not work on production
    //  there is no prod-go-experiments on AWS, and it throws a 403, will switch back soon
    let path = `${this.URL}/${this.experimentPath}/${encodeURIComponent(minix)}`;
    // let path = `${this.env.BASEURL}/experiments/${encodeURIComponent(minix)}`;

    let qParams = [(offset ? 'offset=' + offset : ''), (limit ? 'limit=' + limit : '')]
    .filter(val => val !== '');
    if (qParams.length) {
      path = path + '?' + qParams.join('&');
    }
    return this.http.get<ExperimentItem[]>(path, this.buildRequestOptions()).pipe(
      shareReplay(),
      tap(x => console.log('stasis.getExperiment', x)
      )
    );
  }

  getBinsFromJob(job: string, method: string, limit?: number, offset?: number): Observable<JobBin[]> {
    let path = [
      this.URL,
      'job',
      'bins',
      encodeURIComponent(job),
      encodeURIComponent(method)
    ].join('/');
    let qParams = [(limit ? 'limit=' + limit : ''), (offset ? 'offset=' + offset : '')]
    .filter(p => !!p);
    path = path + (qParams.length ? '?' + qParams.join('&') : '');
    return this.http.get<JobBin[]>(path, this.buildRequestOptions());
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add(`StasisService: ${message}`);
  }
}
