export class JobStatus {
  'job_info': {
    durations: any;
    id: string;
    job: string;
    logGroup: string;
    logStream: string;
    method: string;
    optional?: any;
    'past_states': string[];
    profile: string;
    reason: string;
    resource: string;
    state: string;
    timestamp: number;
  };
  'job_state': string;
}
