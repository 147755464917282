<nav class="navbar navbar-fiehnlab navbar-toggleable-sm navbar-expand-md navbar-dark">
  <a class="navbar-brand ms-3" routerLink="/">LC-BinBase Control Panel</a>

  <button class="navbar-toggler me-3" type="button" (click)="navbarCollapsed = !navbarCollapsed"
          [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarContent" [ngbCollapse]="navbarCollapsed">
    <ul class="navbar-nav me-auto d-flex flex-wrap">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-list-check me-1"></i>Acquisition
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/acquisition/lcms">Create LC-MS Acquisition Table</a>
          <a class="dropdown-item disabled" routerLink="/acquisition/gcms">Create GC-MS Acquisition Table</a>
          <a class="dropdown-item" routerLink="/acquisition/downloads">Download Acquisition Files</a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-droplet me-1"></i>Experiments
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/experiment/main">MiniX Search and Sample Updates</a>
          <!-- Moved here for visual balance, can move it down to scheduler if necessary -->
          <a class="dropdown-item" routerLink="/experiment/sample">Sample Search</a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-calendar me-1"></i>Jobs and Samples
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/scheduler/dataViewer">Job Search</a>
          <a class="dropdown-item" routerLink="/scheduler/container">Create a Job</a>
          <a class="dropdown-item" routerLink="/scheduler/group-job">Group Jobs</a>
          <a class="dropdown-item" routerLink="/scheduler/metadata">Job Metadata</a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-chart-column me-1"></i>BinView
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/binview/binview">BinView</a>
          <a class="dropdown-item" routerLink="/binview/compound">Compound Search</a>
          <a class="dropdown-item" routerLink="/binview/duplicates">Duplicates and Fragment Ions</a>
          <a class="dropdown-item" routerLink="/binview/id">Upload Identifiers</a>
        </div>
      </li>

      <li *ngIf="false" class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-signal me-1"></i>Standards Mgmt
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/standards">Available Methods</a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-clock-o me-1"></i>Events
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/experiment/main">Sample events</a>
          <a class="dropdown-item" routerLink="/scheduler/job-events">Job events</a>
          <a class="dropdown-item" routerLink="/experiment/events">All events</a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa-solid fa-screwdriver-wrench me-1"></i>Utilities
        </a>
        <div class="dropdown-menu app-menu" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/utility/eic">View EIC</a>
          <a class="dropdown-item" routerLink="/utility/spectrum">View Spectrum</a>
          <a class="dropdown-item" routerLink="/utility/transpose">Edit Formatting</a>
        </div>
      </li>

      <!--
            <li class="nav-item">
              <a class="nav-link" href="/monitor" aria-haspopup="true" aria-expanded="false">
                <i class="fa-solid fa-eye me-1"></i>Monitor
              </a>
            </li>
      -->
    </ul>
    <ul class="navbar-nav ms-auto">
      <li class="nav-item" placement="bottom-right" display="dynamic" ngbDropdown>
        <a class="nav-link me-3" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-user-circle me-1"></i> {{ userId$ | async }}
        </a>
        <div class="dropdown-menu user-menu" ngbDropdownMenu>
          <a class="dropdown-item" (click)="logOut()">SignOut</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
