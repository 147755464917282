<div class="container">
  <h1>Plain category list:</h1>
  <div *ngIf="categoriesBrief$ | async as categoriesBrief">
    <p *ngFor="let cat of categoriesBrief">{{ cat }}</p>
  </div>
  <hr/>

  <h1>Full category list:</h1>
  <div *ngIf="categoriesFull$ | async as categoriesFull">
    <div *ngFor="let cat of categoriesFull">
      <hr>
      <p>Category: {{ cat.category }}</p>
      <p>Profiles: </p>
      <p class="m-2" *ngFor="let prof of cat.profiles"> {{ prof | json }}</p>
    </div>
  </div>
</div>
