export class JobBin {
  hidden: boolean;
  method: string;
  name: string;
  precursor_mass: number;
  retention_index: number;
  retention_time: number;
  sample: string;
  selected?: boolean;
  splash: string;
  target_type: string;
  updated: string;
  version: string;
}