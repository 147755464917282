import { Component, inject, OnInit } from '@angular/core';
import { AggregationService } from './services/aggregation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryFullModel } from './model/category-full-model';
import { ProfileModel } from './model/profile-model';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestComponent implements OnInit {
  aggSvc = inject(AggregationService);

  constructor() {
  }

  private subscriptions = new Subscription();

  private categoriesBriefSubject = new BehaviorSubject<string[]>([]);
  private categoriesFullSubject = new BehaviorSubject<CategoryFullModel[]>([]);
  private cat1ProfilesSubject = new BehaviorSubject<ProfileModel[]>([]);

  categoriesBrief$ = this.categoriesBriefSubject.asObservable();
  categoriesFull$ = this.categoriesFullSubject.asObservable();
  cat1Profiles$ = this.cat1ProfilesSubject.asObservable();

  loadProfiles(cat: string) {
    this.subscriptions.add(
      this.aggSvc.getProfilesForCategory$(cat).subscribe(profiles => this.cat1ProfilesSubject.next(profiles))
    );
  }

  ngOnInit() {
    this.subscriptions.add(
      this.aggSvc.getCategoryListPlain$().subscribe(categories => this.categoriesBriefSubject.next(categories))
    );

    this.subscriptions.add(
      this.aggSvc.getCategoryListFull$().subscribe(categories => this.categoriesFullSubject.next(categories))
    );

    // this.cat1Profiles$.subscribe(profiles => this.loadProfiles('data_reduction'))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
