export class Chromatography {
  method: string;
  instrument: string;
  column: string;
  ionisation: string;
  platform: string;

  constructor(method: string, instrument: string, column: string, ionisation: string, platform: string) {
    this.method = method;
    this.instrument = instrument;
    this.column = column;
    this.ionisation = ionisation;
    this.platform = platform;
  }
}
