import { Component, Input, OnInit } from '@angular/core';
import { Istd } from '../../models/Istd';
import { IstdService } from '../../services/istd.service';
import { MatDialog } from '@angular/material/dialog';
import { IstdEditComponent } from '../istd-edit/istd-edit.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-istd-view',
  templateUrl: './istd-view.component.html',
  styleUrls: ['./istd-view.component.css']
})
export class IstdViewComponent implements OnInit {

  @Input()
  istd: Istd;

  constructor(
    private istdStore: IstdService,
    private istdAddDlg: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  getSingleIstd() {
    this.istdStore.getsingleistd()
    .pipe(
      tap(x => console.log(`from istd view: ${JSON.stringify(x)}`))
    )
    .subscribe(data => alert(JSON.stringify(data)));
  }

  editIstd(pIstd: Istd): void {
    const [method, instrument, column, ion_mode] = pIstd.method.split(' | ');
    const nIstd = {...pIstd, ...{method, instrument, column, ion_mode}};

    this.istdAddDlg.open(IstdEditComponent, {
      data: {istd: nIstd, mode: 'edit'},
      width: '1024px',
      height: '90%',
      backdropClass: 'backdropBackground',
    }).afterClosed()
      .pipe(
        tap(x => console.log('modified istd', x)),
      );
  }
}
