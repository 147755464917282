import { Pipe, PipeTransform } from '@angular/core';
import { Method } from '../models/Method';

@Pipe({
  name: 'method'
})
export class MethodPipe implements PipeTransform {

  transform(value: Method, ...args: unknown[]): string {
    return `${value.name} | ${value.instrument} | ${value.column} | ${value.ionMode}`;
  }

}
